import React, { } from 'react';

import { Icon } from "semantic-ui-react";

import { api } from "../api";
import ContentService from "../content/contentService";
import BuildingMenu from "../components/BuildingMenu";
import LocationStep from "../components/building_settings/LocationStep";
import ConnectionStep from "../components/building_settings/ConnectionStep";
import FileImportStep from "../components/building_settings/FileImportStep";
import DeleteBuilding from "../components/building_settings/DeleteBuilding";

class BuildingSettings extends React.Component {
  constructor(props) {
    super(props);
    // Initialize content loading
    this.contentService = new ContentService("building-settings");

    this.state = {
      contentLoaded: false,
      building: undefined,
    };
    this.props.setBuildingId(this.props.match.params.buildingId);
  }

  componentDidMount() {
    if (!this.state.contentLoaded) {
      // Load all content in parallel
      Promise.all([
        this.contentService.loadContent()
      ]).then(([content]) => {
        this.setState({
          contentLoaded: true
        });
      }).catch(error => {
        console.error("Error loading content:", error);
      });
    }
    const buildingPromise = this.props.match.params?.buildingId
      ? api("/building/def/" + this.props.match.params.buildingId)
      : Promise.resolve({});
  
    Promise.all([buildingPromise])
      .then(([ building]) => {
        this.setState({
          building,
        });
      })
      .catch((err) => {
        console.error("Error loading building data:", err);
      });
    // }
  }

  render() {
    if (!this.state.contentLoaded) {
      return <div className="ui active small inline loader" />;
    }
    if (this.state.building === undefined)
      return (
        <div style={{ background: "#f4f4f4" }}>
          {/* <BuildingMenu {...this.props} /> */}
          <div
            className="ui loading segment"
            style={{
              border: "none",
              padding: "10em",
              width: "100%",
              height: "calc(100% - 100px)",
              margin: "0 auto",
            }}
          ></div>
        </div>
      );
    return (
      <div style={{ background: "#f4f4f4" }}>
        {this.props.match.params?.buildingId ? (
          <BuildingMenu {...this.props} />
        ) : null}
        <div
          className="_page"
          style={{
            border: "none",
            padding: "1em",
            maxWidth: 1200,
            margin: "0 auto",
          }}
        > 
          <h3 className="ui top attached header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Icon name="cog" style={{ alignSelf: 'stretch', fontSize: '1.6em',marginTop: '10px' , marginRight: '0px' }}  />
              <div className="content" style={{ textAlign: 'left', marginLeft: '10px' }}>
                {this.contentService.getContent('settings', 'title', `Settings`)}
                <span className="sub header">
                  {this.contentService.getContent('settings', 'sub-heading')}
                </span>
              </div>
            </div>
            <a href={this.contentService.getContent('btn-information', 'link')} target="_blank" title={this.contentService.getContent('btn-information', 'link-tooltip')} rel="noopener noreferrer" style={{ marginLeft: 'auto' }}>
              <button className="help-button ui blue basic button">
                <Icon name="question circle outline" style={{ alignSelf: 'stretch',marginTop: '-5px' , fontSize: '1.3em'}}/>
                {this.contentService.getContent('btn-information', 'button-text')}
              </button>
            </a>
          </h3>
          {/* Connection part */}
          <h3 className="ui top attached header">
            <Icon name="plug" />
            <div className="content">
              {this.contentService.getContent('connection','title')}
              <span className="sub header">
              {this.contentService.getContent('connection','sub-heading')}
              </span>
            </div>
          </h3>
          <div className="ui attached segment">
            {this.state.errorMessage ? (
              <div className="ui negative message">
                {this.state.errorMessage}
              </div>
            ) : null}
            <ConnectionStep
              ref={(ref) => {
                this.ConnectionStep = ref;
              }}
              building={this.state.building}
              routerHistory={this.props.history}
            />
          </div>
          {this.props.match.params?.buildingId ? (
            <>
              {/* File Import part */}
              <h3 className="ui top attached header">
                <Icon name="file alternate outline" />
                <div className="content">
                {this.contentService.getContent('file-import','title')}
                  <span className="sub header">
                  {this.contentService.getContent('file-import','sub-heading')}</span>
                </div>
              </h3>
              <div className="ui attached segment">
                <div className="ui ignored info message">
                  <div className="header">
                  {this.contentService.getContent('file-import-content','header')}
                  </div>
                  <ul className="list" 
                    dangerouslySetInnerHTML={this.contentService.getContent('file-import-content','list', '' , true)}
                  />
                </div>
                <FileImportStep building={this.state.building} />
              </div>
              {/* Location and Timezone part */}
              <h3 className="ui top attached header">
                <Icon name="globe" />
                <div className="content">
                {this.contentService.getContent('location','title')}
                  <span className="sub header">
                  {this.contentService.getContent('location','sub-heading')}
                  </span>
                </div>
              </h3>
              <div className="ui attached segment">
                <div className="ui ignored info message">
                  <div className="header" >
                    {this.contentService.getContent('time-source-content','header')}
                  </div>
                  <ul className="list"
                     dangerouslySetInnerHTML={this.contentService.getContent('time-source-content','list',undefined, true)}
                  />
                </div>
                <LocationStep building={this.state.building} />
              </div>
              <DeleteBuilding
                buildingName={this.state.building.name}
                buildingID={this.state.building._id}
                routerHistory={this.props.history}
                setBuildingId={this.props.setBuildingId}
              />
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default BuildingSettings;
