import React, { createRef } from "react";
import { Icon } from 'semantic-ui-react';
import { api } from "../../api";
import ContentService from "../../content/contentService";
import { isEqual } from "lodash";

const guid = () => {
  const s4 = () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};

const uploadButtonStyle = {
  width: "150px",
};

const fileSelectdButtonStyle = {
  marginRight: "10px",
  width: "150px",
};

class FileImportStep extends React.Component {
  constructor(props) {
    super(props);
    this.contentService = new ContentService("projectfile-settings");

    // console.log(`FILE IMPORT STEP`, props.building);
    this.state = {
      contentLoaded: false,
      status: "success",
      statusMessage: undefined,
      statusIcon: undefined,
      project_uploaded: undefined,
      processing: false,
      submitButtonDisabled: false,
      buildingChanges: undefined,
      changeCounts: undefined,
      fileSelected: false,
      passwordRequired: false,
      password: ""
    };
  }

  componentDidMount() {
    this.contentService.loadContent().then(() => {

      if(this.props.building.project_uploaded === false){
        const statusMsg = this.contentService.getContent('project-file-not-loaded','warning',"Please Upload your ETS Project File.")
        this.setState({ contentLoaded: true, statusMessage: statusMsg , statusIcon: { name: "hand point right", size: "large", color: "red" }   });
      }
      this.setState({ contentLoaded: true });
    });
  }

  handlePasswordCancel = () => {
    this.setState({
      statusMessage: "",
      status: "warning",
      processing: false,
      fileSelected: false,
      passwordRequired: false,
    });
  }
  
  handlePasswordSubmit = () => {
    const { password } = this.state;
    api("/building/etsimport/retry", {
      method: "POST",
      headers: { "Content-Type": "application/json", "x-access-token": window.accessToken },
      body: JSON.stringify({ id: this.buildingUploadId,password: password })
    })
    .then(() => {
      this.setState({
        statusMessage: this.contentService.getContent('project-file-password-retry','status',"Retrying with provided password..."),
        status: "warning",
        processing: true,
        passwordRequired: false,
      });
      this.startFileStatusCheck();
    })
    .catch((err) => {
      this.setState({
        statusMessage: this.contentService.getContent('project-file-password-error','status',"Error submitting password: " + err.toString()),
        status: "error",
        processing: false,
      });
    });
  };

  uploadFile() {
    let files = this.wrapper.current.querySelector("input[type='file']").files;

    if (files && files[0]) {
      let file = files[0];
      var data = new FormData();
      data.append("file", file);

      this.buildingUploadId = guid();

      window.$.ajax({
        url: `${window.location.origin}/api/building/etsimport/${this.buildingUploadId}`,
        type: "POST",
        headers: { "x-access-token": window.accessToken },
        data: data,
        processData: false,
        contentType: false,
        success: () => {
          this.setState({ statusMessage: "Uploading...", status: "warning",fileSelected: false, processing: true });
          this.startFileStatusCheck();
        },
      });
    }
  }

  startFileStatusCheck() {
    // console.log(`startFileStatusCheck  ${this.buildingUploadId} `)
    this.fileStatusTimer = setInterval(() => {
      api(`/building/etsimport/${this.buildingUploadId}`)
        .then((res) => {
          if (this.closed === true) return;
          if (res.status) {
            this.setState({
              statusMessage: this.contentService.getContent('project-upload-processing','status'," Processing File: ") + res.status.replace(".xml", ""),
              status: "warning",
              processing: true,
            });

            if (res.status === "password_required") {
              clearInterval(this.fileStatusTimer);
              this.setState({
                statusMessage: this.contentService.getContent('project-upload-need-password','status',"Your ETS Project File requires a password. Please enter it above."),
                status: "warning",
                passwordRequired: true,
                processing: false,
              });
            }

            if (res.status === "wrong_password") {
              clearInterval(this.fileStatusTimer);
              this.setState({
                statusMessage: this.contentService.getContent('project-upload-wrong-password','status',"Your password was incorrect. Please try again or upload a project file that is not Password Protected. (") + res.document + ")",
                status: "warning",
                passwordRequired: true,
                processing: false,
              });
            }

            if (res.status === "error") {
              clearInterval(this.fileStatusTimer);
              this.setState({
                statusMessage: res.document || this.contentService.getContent('project-upload-status-error','status',"Error Processing File."),
                status: "error",
                processing: false,
                passwordRequired: false,
              });
            }

            if (res.status === "complete") {
              clearInterval(this.fileStatusTimer);
              this.setState({
                statusMessage: this.contentService.getContent('project-upload-status-compare','status'),
                status: "warning",
                processing: true,
              });

              this.loadExistingData(res.document);
            }
          }
        })
        .catch((err) => {
          clearInterval(this.fileStatusTimer);
          this.setState({
            statusMessage: this.contentService.getContent('project-upload-progress-error','status',`Error loading progress: `) + err,
            status: "error",
            processing: false,
            passwordRequired: false,
          });
        });
    }, 2000);
  }

  loadExistingData(newData) {

    // console.log(newData);

    Promise.all([
      api("/building/group/" + this.props.building._id),
      api("/building/device/" + this.props.building._id),
      api("/building/part/" + this.props.building._id),
    ])
      .then((existing_data) => {
        if (this.closed === true) return;

        let changes = {
          groups: this.diff(existing_data[0].groups, newData.groups, ["id", "name", "alias", "dpt", "isRange"]),
          devices: this.diff(existing_data[1].devices, newData.devices, ["id", "address", "name", "coms", "ordernumber", "device"]),
        };

        let changeCounts = [];
        for (const section of ["devices", "groups"]) {
          for (const kind of ["added", "updated"]) {
            if (Object.keys(changes[section][kind]).length > 0) {
              console.log(section,kind)
              changeCounts.push({
                section: section,
                kind: kind,
                count: Object.keys(changes[section][kind]).length,
              });
            }
          }
        }

        if (!isEqual(newData.buildingParts, existing_data[2].buildingParts)) {
          changes.building_parts = newData.buildingParts;
          changeCounts.push({
            section: "building parts",
            kind: "updated",
            count: "",
          });
        }

        if (changeCounts.length === 0) {
          this.setState({
            statusMessage: this.contentService.getContent('project-upload-status-same','status'),
            status: "success",
            processing: false,
            passwordRequired: false,
            changeCounts: undefined,
            buildingChanges: undefined,
          });
        } else {
          this.setState({
            statusMessage: undefined,
            status: "success",
            processing: false,
            passwordRequired: false,
            changeCounts: changeCounts,
            buildingChanges: changes,
          });
        }
      })
      .catch((err) => {
        this.setState({
          statusMessage: this.contentService.getContent('project-upload-loading-error','status',`Error loading existing data to compare err: `) + err.toString(),
          status: "error",
          processing: false,
          passwordRequired: false,
        });
      });
  }

  diff(old_data, new_data, fields) {
    let changes = { added: {}, updated: {} };
    for (const address in new_data) {
      if (old_data[address] === undefined) {
        changes.added[address] = new_data[address];
      } else {
        let hasChanges = false;
        let update = {};
        for (const field of fields) {
          if (
            new_data[address][field] !== undefined &&
            (old_data[address][field] === undefined || !isEqual(new_data[address][field], old_data[address][field]))
          ) {
            update[field] = new_data[address][field];
            hasChanges = true;
          }
        }
        if (hasChanges) {
          changes.updated[address] = update;
        }
      }
    }
    return changes;
  }

  handleFileSelect = (e) => {
    let files = this.wrapper.current.querySelector("input[type='file']").files;
    var file = null;

    if (files && files[0]) {
      file = files[0];
      // console.log(file);
    }

    this.setState({ fileSelected: e.target.files.length > 0, statusIcon: { name: "info" } ,statusMessage: `Project: ${file.name}  Size: ${Math.round(file.size / 1024,0)}KB  Last Modified: ${file.lastModifiedDate} `});
  };

  componentWillUnmount() {
    this.closed = true;
    if (this.fileStatusTimer) {
      clearInterval(this.fileStatusTimer);
    }
  }

  wrapper = createRef();

  render() {
    return (
      <div ref={this.wrapper}>

        <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '10px' }}>
            {this.state.passwordRequired && (
              <form id="pULP" className="ui form uploader" encType="multipart/form-data" autoComplete="off">
                <Icon name = "lock" color="grey" />                
                <input
                  type="password"
                  value={this.state.password}
                  onChange={(e) => this.setState({ password: e.target.value })}
                  placeholder={this.contentService.getContent('project-password-placeholder', 'placeholder', `Password`)}
                  style={{ gap: '10px', marginRight: '10px' ,width: '150px',height: '32px' }}
                  name="projPassword"
                  autoComplete="off"
                />
                <button
                  type="button"
                  className="ui black basic button" 
                  onClick={this.handlePasswordSubmit} // Triggers the file input click
                  style={fileSelectdButtonStyle}
                  autoComplete="off"                  
                  >
                  <Icon name='unlock' />
                  {this.contentService.getContent('project-password-submit', 'button-text', `Submit Password`)}
                </button>

                <button
                  type="button"
                  className="ui red basic button" // Use the same styling class
                  onClick={this.handlePasswordCancel}
                  style={fileSelectdButtonStyle}
                  >
                  <Icon name='cancel' />
                  {this.contentService.getContent('project-password-cancel', 'button-text', `Cancel`)}
                </button>
              </form>
            )}

        
            {!this.state.passwordRequired && (
              <form id="pUL" className="ui form uploader" encType="multipart/form-data" autoComplete="off">
                {/* Hidden file input */}
                <input
                  type="file"
                  name="file"
                  className="upload-file"
                  accept=".knxproj"
                  onChange={this.handleFileSelect}
                  style={{ display: 'none' }} // Hides the default file input
                  ref={(fileInput) => this.fileInput = fileInput} // Reference to trigger click
                />

                {/* Custom styled button */}
                <button
                  type="button"
                  className="ui black basic button" // Use the same styling class
                  onClick={() => this.fileInput.click()} // Triggers the file input click
                  style={fileSelectdButtonStyle}
                  >
                  <Icon name='file outline' />
                  {this.contentService.getContent('project-file-select', 'button-text', `Select Project File`)}
                </button>
              </form>
            )}

            {this.state.fileSelected && !this.state.passwordRequired && !this.state.processing && (
              <button
                className="ui basic positive button"
                disabled={this.state.processing}
                style={uploadButtonStyle}
                onClick={(e) => this.uploadFile(e)}
                >
                <Icon name='cloud upload' />
                {this.contentService.getContent('project-upload-btn-upload', 'button-text')}
              </button>
            )}  

        </div>

        {this.state.statusMessage && (
          <div className={`ui ${this.state.status} message`}>
            {this.state.processing && ( <> <div className="ui active small inline loader" />{" "} </>)}
            {this.state.statusIcon && (<Icon  {...this.state.statusIcon} /> )}{this.state.statusMessage}
          </div>
        )}

        {Array.isArray(this.state.changeCounts) &&
          this.state.changeCounts.length > 0 ? (
            <div className="ui clearing segment" style={{ maxWidth: 500 }}>
              <table className="ui green table">
                <tbody>
                  {this.state.changeCounts.map((change) => {
                    return (
                      <tr key={change.section}>
                        <td>{change.section}</td>
                        <td>
                          {change.count}{" "}
                          <span className="label">{change.kind}</span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <button
                className="ui right floated green basic button"
                disabled={this.state.submitButtonDisabled}
                style={{ gap: '10px',width: '150px',height: '32px' }}
                onClick={() => {
                  this.setState({
                    submitButtonDisabled: true,
                    statusMessage: this.contentService.getContent('building-update-progress','text',`Saving ... `),
                    status: "warning",
                    processing: true,
                    fileSelected: false,
                  });
                  // save changes
                  api("/building/update/" + this.props.building._id, {
                    method: "POST",
                    body: JSON.stringify(this.state.buildingChanges),
                  })
                  .then((/*res*/) => {
                    this.setState({
                      buildingChanges: undefined,
                      // project_uploaded: true,
                      changeCounts: undefined,
                      submitButtonDisabled: false,
                      statusMessage: this.contentService.getContent('building-update-success','text',`All changes to KNX Project saved`),
                      status: "success",
                      processing: false,
                      passwordRequired: false,
                      fileSelected: false,
                    });
                  })
                  .catch((err) => {
                      this.setState({
                        submitButtonDisabled: false,
                        statusMessage: this.contentService.getContent('building-update-error','text',`There was an error saving Error: ${err}`),
                        status: "error",
                        processing: false,
                        passwordRequired: false,
                        fileSelected: false,
                      });
                    });
                }}
              >
                <Icon name='save' /> 
                {this.contentService.getContent('building-update-btn-save','button-text')}
              </button>
              <button
                className="ui right floated red basic button"
                disabled={this.state.submitButtonDisabled}
                style={{ gap: '10px',   marginRight: "10px", width: '150px',height: '32px' }}
                onClick={() => {
                  this.setState({
                    buildingChanges: undefined,
                    changeCounts: undefined,
                    statusMessage: undefined,
                    status: "success",
                    processing: false,
                    fileSelected: false,
                  });
                }}
              ><Icon name='trash' /> 
                {this.contentService.getContent('building-update-btn-discard','button-text')}
              </button>
            </div>
          ) : undefined}        

      </div>
    );
  }
}

export default FileImportStep;
