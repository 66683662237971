import React, { useCallback, useState, useRef, useEffect  } from "react";
import moment from "moment";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import AutocompleteInput from "./AutoCompleteInput"; // Import the new component

import { Icon } from 'semantic-ui-react';
import { api } from "../../api";
import ContentService from "../../content/contentService";
import FieldAndCheckboxCombo from "./FieldAndCheckboxCombo";

const libraries = ["places","marker"];

const defaultCenter = {
  lat: 50.888951,
  lng: 4.449588,
};

const saveChangeButtonStyle = {
  // position: "absolute",
  // left: "0em",
  // bottom: "1.5em",
  marginTop: "25px",
  marginLeft: "10px",
  width: "150px",
};

const LocationStep = (props) => {
  
  const contentService = useRef(new ContentService("location-settings"));
  const [contentLoaded, setContentLoaded] = useState(false);
  
  const {isLoaded} = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    id: 'google-map-script',
    mapIds: [process.env.REACT_APP_MAP_ID],
    libraries,
  });

  const defaultLocation = {
    lat: props.building.latitude || defaultCenter.lat,
    lng: props.building.longitude || defaultCenter.lng,
  };

  // const [marker, setMarker] = useState(defaultLocation);
  const [position, setPosition] = useState(defaultLocation);
  const [changed, setChanged] = useState(false);
  const buildingTimers = props.building.timers || {};
  const [dateField, setDateField] = useState(buildingTimers.date || "");
  const [timeField, setTimeField] = useState(buildingTimers.time || "");
  const [dateTimeField, setDateTimeField] = useState(
    buildingTimers.datetime || ""
  );
  const [timerInterval, setTimerInterval] = useState(
    buildingTimers.interval || ""
  );
  const [errors, setErrors] = useState({ dateField: '', timeField: '', dateTimeField: '' });
  const [timerSource, setTimerSource] = useState(buildingTimers.source || "");
  const [loaded, setLoaded] = useState(false);
  const mapRef = useRef();
  const markerRef = useRef();

  // Load content on component mount
  useEffect(() => {
    if (!contentLoaded) {
      // console.log('loading content');
      contentService.current.loadContent().then(() => setContentLoaded(true));
      // setContentLoaded(true)
    }
  }, [contentLoaded]);

  useEffect(() => {
    
    loaded ? setChanged(true) : setLoaded(true);

  }, [ dateField, timeField, dateTimeField]);

  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
    if (window.google) {
      // Create an AdvancedMarkerElement when the map loads
      markerRef.current = new window.google.maps.marker.AdvancedMarkerElement({
        map,
        position
      });
    }
  }, [position]);

  const panTo = useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(11);
    setPosition({ lat, lng });
    setChanged(true);
  }, []);

  useEffect(() => {
    // Update marker position whenever 'position' changes
    if (isLoaded &&  markerRef.current) {
      markerRef.current.position = position;
    }
  }, [isLoaded,position]);

  const validateAddress = (address) => {
    if (!address) {
      return false;
    }
    const val = address.trim();
    const addr = val.match(/(\d)+(\.(\d)+){1,2}/g);
    if (addr) {
      if (Array.isArray(addr)) {
        if (addr[0]) return true;
      }
    }
    return false;
  };

  const handleSave = () => {
    let changes = Object.assign(
      {},
      {
        latitude: position.lat,
        longitude: position.lng,
        timers: {
          interval: timerInterval,
          source: timerSource,
        },
        showingInfoWindow: false,
        activeMarker: {},
        selectedPlace: {},
      }
    );
    if (dateField) changes.timers.date = dateField;
    if (timeField) changes.timers.time = timeField;
    if (dateTimeField) changes.timers.datetime = dateTimeField;
    if (changes.timers) {
      if (changes.timers.interval === undefined) changes.timers.interval = 1;
      if (changes.timers.source === undefined) changes.timers.source = "1.1.200";
    }
    if (
      changes.latitude === defaultCenter.lat &&
      changes.longitude === defaultCenter.lng
    ) {
      delete changes.latitude;
      delete changes.longitude;
    }
    Object.assign(props.building, changes);
    api("/building/update/" + props.building._id, {
      method: "POST",
      body: JSON.stringify(changes),
    }).then(setChanged(false));
  };

  const validateField = (field, value) => {
    const regex = /^([0-2]?[0-9]|3[01])\/([0-7])\/([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-4])$/;
    if (!regex.test(value)) {
      if(value === ""){
        return '';
      } else {
        return contentService.current.getContent(`${field}-group-format`,'validation')
      }
    }
    return '';
  };

  const handleDateFieldChange = (value) => {
    setDateField(value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      dateField: validateField('dateField', value),
    }));
  };

  const handleTimeFieldChange = (value) => {
    setTimeField(value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      timeField: validateField('timeField', value),
    }));
  };

  const handleDateTimeFieldChange = (value) => {
    setDateTimeField(value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      dateTimeField: validateField('dateTimeField', value),
    }));
  };

  if (!contentLoaded) {
    return <div className="ui active small inline loader" />;
  }

  return (
    <div className="ui form">
      {/* Location and google maps */}
      <div
        className="ui two column middle aligned very relaxed stackable grid"
        style={{ position: "relative" }}
      >
        {isLoaded ? (
          <div className="gmap column">
            { contentService.current.getTitleAndInfoPopup('location-map-search')}
            <AutocompleteInput 
              onPlaceSelected={panTo} // Use panTo as the callback when a place is selected
              position={position}
              contentService={contentService} 
            />
            {/* <Search position={position} panTo={panTo} contentService={contentService} /> */}
            <div className="mapControl">
              <GoogleMap
                mapContainerStyle={{ height: "100%", width: "100%" }}
                center={position}
                zoom={11}
                // onClick={onMapClick}
                onLoad={onMapLoad}
                options={{ mapId: '71a9913179373cac' }} 
              />
            </div>
          </div>
        ) : "Loading Maps"}
        {/* <div className="ui vertical divider">Or</div> */}
        <div className="column">
          <div className="fields">
            <div className="eight wide latitude field">
              <label>
              { contentService.current.getTitleAndInfoPopup('location-map-latitude')}
                <input
                  type="text"
                  value={position.lat}
                  onChange={(e) => {
                    setPosition({
                      lat: Number(e.target.value),
                      lng: position.lng,
                    });
                    setChanged(true);
                  }}
                />
              </label>
            </div>
            <div className="eight wide longitude field">
              <label>
              { contentService.current.getTitleAndInfoPopup('location-map-longitude')}
                <input
                  type="text"
                  value={position.lng}
                  onChange={(e) => {
                    setPosition({
                      lng: Number(e.target.value),
                      lat: position.lat,
                    });
                    setChanged(true);
                  }}
                />
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="ui section divider"></div>
      {/* Building Timers */}
      <div className="fields">
        <div className="sixteen wide field">
          <p>
          <Icon name='clock outline' size="large"/>
            {contentService.current.getTitleAndInfoPopup('location-time-functions')}
         </p>
          <p>{contentService.current.getContent('location-time-functions','sub-heading')}</p>
        </div>
      </div>
      <div className="ui segment fields" style={{marginLeft: '0px', marginRight: '0px'}}>
        {/* Date Timer */}
        <FieldAndCheckboxCombo
          name="dateField"
          field={dateField}
          setField={handleDateFieldChange}
          label={
            <label>
              <b>{contentService.current.getTitleAndInfoPopup('location-time-date')}</b><br />
              <small>ex: {moment().format("DD-MM-YYYY")}</small>
            </label>
          }
        />

        {/* Time Timer */}
        <FieldAndCheckboxCombo
          name="timeField"
          field={timeField}
          setField={handleTimeFieldChange}
          label={
            <label>
              <b>{contentService.current.getTitleAndInfoPopup('location-time-time')}</b><br />
              <small>ex: {moment().format("HH:mm:ss")}</small>
            </label>
          }
        />

        {/* DateTime Timer */}
        <FieldAndCheckboxCombo
          name="dateTimeField"
          field={dateTimeField}
          setField={handleDateTimeFieldChange}
          label={
            <label>
              <b>{contentService.current.getTitleAndInfoPopup('location-time-datetime')}</b><br />
              <small>ex: {moment().format("DD-MM-YYYY HH:mm:ss")}</small>
            </label>
          }
        />
      </div>

      {/* Timer settings*/}
      <div className="four fields" style={{marginBottom: '0px'}}>
        <div className="field"> 
          {/* Save change, hidden when new building */}
          {props.building._id && changed && errors.dateField === "" && errors.timeField === "" && errors.dateTimeField === "" ? (
          <div className="fields">
            <button
              className="ui positive basic button"
              style={saveChangeButtonStyle}
              onClick={() => handleSave()}
            >
              <Icon name='save' />
              {contentService.current.getContent('btn-save-changes', 'button-text')}
            </button>
          </div>
      ) : undefined}
        </div>
        <div className="field" />
        <div className="field">
          <label>
          {contentService.current.getTitleAndInfoPopup('location-time-frequency')}
            <select
              className="ui dropdown"
              defaultValue={timerInterval}
              selected={timerInterval}
              style={{height: '35px'}}
              onBlur={(e) => {
                setChanged(true);
                setTimerInterval(Number(e.target.value));
              }}
              onChange={(e) => {
                setChanged(true);
                setTimerInterval(Number(e.target.value));
              }}
            >
              <option value="1">{contentService.current.getContent('time-frequency-minute','option',`Every Minute`)}</option>
              <option value="5">{contentService.current.getContent('time-frequency-five-minutes','option',`Every 5 minutes`)}</option>
            </select>
          </label>
        </div>
        <div className="field">
          <label>
            {contentService.current.getTitleAndInfoPopup('location-time-source')}
            <input
              type="text"
              placeholder="1.1.200"
              defaultValue={timerSource}
              onChange={(e) => {
                setChanged(true);
                let val = e.target.value.trim();
                if (!val) val = "1.1.200";

                if (validateAddress(val)) {
                  window.$(e.target).closest(".field").removeClass("error");
                  setTimerSource(val);
                } else {
                  window.$(e.target).closest(".field").addClass("error");
                }
              }}
            />
          </label>
        </div>
      </div>
      {errors.dateField || errors.timeField || errors.dateTimeField ? (
      <div className="field">
        <div className="ui negative message" style={{ display: 'block', marginTop: '5px' }}>
            {errors.dateField ? errors.dateField : ''}
            {errors.timeField ? errors.timeField : ''}
            {errors.dateTimeField ? errors.dateTimeField : ''}
        </div>
      </div>
      ) : <></> }
    </div>
  );
};

export default LocationStep;
