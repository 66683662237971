import moment from "moment";
import numeral from "numeral";

import _datatype_tree_store from "./datatypes_imported";

var _datatype_flat_store = {};
var _datatype_flat_name_store = {};

const formatter_generic = (val) => {
  if (val === undefined || val === null) return "";
  return "" + val;
};

const formatter_numeric = (val) => {
  if (val === undefined || val === null) return "";
  return isFinite(+val) ? numeral(val).format("0,0[.]0[0]") : val;
};

const formatter_boolean = (val) => {
  if (val === undefined || val === null) return "";
  return val ? "ON" : "OFF";
};

const formatter_date = (val) => {
  if (val === undefined || val === null) return "";
  return moment.utc(val).format("DD/MM/YYYY");
};

const formatter_datetime = (val) => {
  if (val === undefined || val === null) return "";
  const now = moment();
  const year = (val.year !== undefined) ? val.year : now.year();
  const month = (val.month !== undefined) ? (val.month - 1) : now.month();
  const day = (val.day !== undefined) ? val.day : now.day();
  const hour = (val.hour !== undefined) ? val.hour : now.hour();
  const minute = (val.minute !== undefined) ? val.minute : now.minute();
  const second = (val.second !== undefined) ? val.second : now.second();
  return moment([year, month, day, hour, minute, second]).format("LLL");
};

const formatter_operating_mode = (val) => {
  if (val === undefined || val === null) return "";
  switch (val) {
    case 0:
      return "Auto";
    case 1:
      return "Comfort";
    case 2:
      return "Standby";
    case 3:
      return "Night";
    case 4:
      return "Building Protection";
    default:
      return "Unknown";      
  }
};

// Recursively walk through data types
const __trav_data_type = (curr, callback, parent) => {
  for (var id in curr) {
    var dpt = curr[id];
    if (parent) dpt.parent = parent;
    callback(dpt);
    if (dpt.children) {
      __trav_data_type(dpt.children, callback, dpt);
    }
  }
};
__trav_data_type(_datatype_tree_store, (dpt) => {
  // if (dpt.converter) {
  // 	dpt.converterName = dpt.converter;
  // 	dpt.converter = require('./base/' + dpt.converter);
  // }
  _datatype_flat_store[dpt.id] = dpt;
  if (_datatype_flat_name_store[dpt.name])
    throw new Error("Duplicated data point type name : " + dpt.name);
  _datatype_flat_name_store[dpt.name] = dpt;

  dpt.formatter = formatter_generic;
  switch (dpt.converter) {
    case "DPT1BitControlled":
    case "DPT3BitControlled":
      break;
    case "DPT2ByteFloat":
    case "DPT2ByteUnsigned":
    case "DPT4ByteFloat":
    case "DPT4ByteSigned":
    case "DPT4ByteUnsigned":
    case "DPT8BitUnsigned":
    case "DPTSceneNumber":
      dpt.formatter = formatter_numeric;
      break;
    case "DPTBoolean":
      dpt.formatter = formatter_boolean;
      break;
    case "DPTDate":
      dpt.formatter = formatter_date;
      break;
    case "DPTDateTime":
      dpt.formatter = formatter_datetime;
      break;
    case "DPTError":
    case "DPTRGB":
    case "DPTSceneControl":
    case "DPTString":
    case "DPTTime":
      break;
  }
  switch (dpt.id) {
    case "DPST-20-102":
    case "DPST-20-110":
      dpt.formatter = formatter_operating_mode;
      break;
  }
});

// APIs
const getById = (id) => {
  return _datatype_flat_store[id];
};
const getByName = (name) => {
  return _datatype_flat_name_store[name];
};

const getAllTypes = () => {
  return _datatype_flat_store;
};

const resolveDPT = (dpt) => {
  let _dpt = getById(dpt);
  if (_dpt === undefined) _dpt = getByName(dpt);
  if (_dpt === undefined) return undefined;

  return _dpt;
};

export { getAllTypes, getByName, getById, resolveDPT };
