import React, { useEffect, useState ,useRef} from "react";
import SubscriptionOption from "../components/SubscriptionOption";

import { api } from "../api";
import ContentService from "../content/contentService";
import "../styles/default.css";

// const TEST_PLANS = [
//   {
//     subscription_plan_id: 10,
//     name: "Free",
//     max_active_buildings: 1,
//     max_timers: 0,
//     max_alerts: 0,
//     stripe_product_id: null,
//     free_trial_days: 3,
//     prices: [{ price: "£10", tenure: "per month", nickname: null, id: null }],
//   },
//   {
//     subscription_plan_id: 20,
//     name: "Basic",
//     max_active_buildings: 5,
//     max_timers: 5,
//     max_alerts: 5,
//     stripe_product_id: null,
//     free_trial_days: 0,
//     prices: [{ price: "free", tenure: null, nickname: null, id: null }],
//   },
//   {
//     subscription_plan_id: 30,
//     name: "Premium",
//     max_active_buildings: 10,
//     max_timers: null,
//     max_alerts: null,
//     stripe_product_id: null,
//     free_trial_days: 0,
//     prices: [
//       { price: "£10", tenure: "per month", nickname: null, id: null },
//       { price: "£10", tenure: "per month", nickname: null, id: null },
//       { price: "£10", tenure: "per month", nickname: null, id: null },
//       { price: "£10", tenure: "per month", nickname: null, id: null },
//       { price: "£10", tenure: "per month", nickname: null, id: null },
//       { price: "£10", tenure: "per month", nickname: null, id: null },
//     ],
//   },
// ];

// const TEST_USER = {
//   email: "joe@bloggs.com",
//   first_name: "Joe",
//   last_name: "Bloggs",
//   roles: [],
//   subscription_plan_name: "Free",
//   subscription_plan_id: 20,
//   subscription_status: "active",
//   subscription_current_period_end: null,
//   has_stripe: true,
//   max_active_buildings: 1,
//   max_alerts: 0,
//   max_timers: 0,
// };

const Subscription = () => {

  const contentService = useRef(new ContentService("subscription"));
  // Content loading state
  const [contentLoaded, setContentLoaded] = useState(false);

  const [plans, setPlans] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {

    if(!contentLoaded){
      contentService.current.loadContent().then(() => {
        setContentLoaded(true);
      });
    }

    getUser();
    getPlans();
  }, [contentLoaded]);

  const getUser = () => {
    api("/user").then((res) => {
      if (res) {
        setUser(res);
      }
    });
  };

  const getPlans = () => {
    api("/payments/plans").then((res) => {
      if (res) {
        setPlans(res);
      }
    });
  };

  if (!contentLoaded) {
    return <div className="ui active small inline loader" />;
  }

  return (
    <div className="app_content">
      <div className="_page">
        <h2 className="content-title">{contentService.current.getContent('page-content','heading')}</h2>
        <p className="content-desc">{contentService.current.getContent('page-content','sub-heading')}
        </p>
        {plans && user && (
          <>
            <ul className="pricing-table col-4">
              {plans && user ? (
                plans.map((plan) => (
                  <li
                    data-animation="true"
                    data-animation-type="fadeInUp"
                    key={plan.subscription_plan_id}
                  >
                    <SubscriptionOption
                      {...plan}
                      contentService={contentService.current}
                      hasStripe={user.has_stripe}
                      user={user}
                      isCurrentPlan={
                        // Only hide the "Manage Subscription" button if their subscription is active, not just trialing etc
                        user.subscription_plan_id === plan.subscription_plan_id && user.subscription_status === "active"
                      }
                    />
                  </li>
                ))
              ) : (
                <li>{contentService.current.getContent('current-subscription-error','error',`Unable to load`)}</li>
              )}
            </ul>
            <h2 className="content-title">{user.first_name}, {contentService.current.getContent('current-subscription', 'title')}</h2>
              <div className="content-desc">
                {user.subscription_plan_name && user.subscription_status !== "canceled" ? (
                  <div className="subscription-container">
                    <div className="subscription-details">
                      <div className="subscription-row">
                        <div className="subscription-label">
                          {contentService.current.getContent('current-subscription-type', 'text', 'Type:')}
                        </div>
                        <div className="subscription-value shadow">
                          {user.subscription_plan_name}
                        </div>
                      </div>
                      <div className="subscription-row">
                        <div className="subscription-label">
                          {contentService.current.getContent('current-subscription-status', 'text', 'Status:')}
                        </div>
                        <div className="subscription-value shadow">
                          {user.subscription_status.replace("_", " ").replace("trialing", contentService.current.getContent('status-free-trialing', 'text', 'FREE TRIAL'))}
                        </div>
                      </div>
                      <div className="subscription-row">
                        <div className="subscription-label">
                          {contentService.current.getContent('current-subscription-end-date', 'text', 'Renewal Date:')}
                        </div>
                        <div className="subscription-value shadow">
                          {contentService.current.getDateInLocalFormat(user.subscription_current_period_end)}
                          {contentService.current.getInfoPopup('current-subscription-end-date',`This is the next date we will take payment for your subscription`)}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <p>{contentService.current.getContent('current-subscription', 'sub-heading-no-subscription')}</p>
                )}
              </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Subscription;
