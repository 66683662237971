// contentService.js
import { Popup, Icon } from "semantic-ui-react";
import ContentCache from "./contentCache";

class ContentService {
  constructor(area, language = "en") {
    this.area = area;
    this.language = language;
    this.content = [];
    this.daemons = null;
    this.connectionTypes = null;
    this.contentLoaded = false;
  }

  async loadContent() {
    if (this.contentLoaded) {
      // If content is already loaded, return it immediately
      return this.content;
    }
    // Load the content asynchronously
    const loadedContent = await ContentCache.loadContent(this.language,this.area);
    this.content = loadedContent //loadedContent[this.area] || []; // Load only content for the specified area
    this.contentLoaded = true; // Mark as loaded
    return this.content;
  }

  async getConnectionTypes() {
    if(!this.connectionTypes){
      // console.log('getConnectionTypes');
      this.connectionTypes = await ContentCache.loadConnectionTypes(this.language);
    }
    return this.connectionTypes;
  }

  async getVPNDaemons() {
    if(!this.daemons){
      // console.log('getVPNDaemons');
      this.daemons = await ContentCache.loadDaemons(this.language);
    }
    const filteredDaemons = await this.daemons.filter((daemon) => daemon.vpndaemon === true);
    return filteredDaemons;
  }

  async getDaemons() {
    if(!this.daemons){
      // console.log('getDaemons');
      this.daemons = await ContentCache.loadDaemons(this.language);
    }
    const filteredDaemons = await this.daemons.filter((daemon) => daemon.vpndaemon === false);
    return filteredDaemons
  }

  getDateInLocalFormat(inputDate) {
    const date = new Date(inputDate);
    return date.toLocaleDateString(this.language); 
  }

  getContent(tag, type, altContent = "", returnHtml = false) {
    const entry = this.content.find((item) => item.tag === tag && item.type === type);
    // if(!entry){
    //   const sql = `INSERT INTO SiteContent (tag, area, type, language, content)
    //   VALUES ('${tag}', '${this.area}', '${type}', 'en', '${altContent}');`
    //   // console.log(entry,entry ,altContent);
    //   console.log(sql);
    // }
    const content = entry ? entry.content : altContent;
    return returnHtml ? { __html: content } : content;
  }

  getTitleAndInfoPopup(fieldName, altTitleText, altInfoText, htmlContent = false) {
    return htmlContent ? (
      <div style={{ display: "inline-flex", alignItems: "center" }}>
        <div dangerouslySetInnerHTML={ this.getContent(fieldName, "title", altTitleText, htmlContent)} />
        <Popup
          content={ this.getContent(fieldName, "info", altInfoText)}
          trigger={<Icon name="info" className="circular" size="small" style={{ color: "#1E3A8A", marginLeft: "5px", cursor: "pointer" }} />}
          position="top center"
        />
      </div>
    ) : (
      <>
        { this.getContent(fieldName, "title", altTitleText)}
        <Popup
          content={ this.getContent(fieldName, "info", altInfoText)}
          trigger={<Icon name="info" className="circular" size="small" style={{ color: "#1E3A8A", marginLeft: "5px", cursor: "pointer" }} />}
          position="top center"
        />
      </>
    );
  }

  getInfoPopup(fieldName, altInfoText) {
    return (
      <>
        <Popup
          content={ this.getContent(fieldName, "info", altInfoText)}
          trigger={<Icon name="info" className="circular" size="small" style={{ color: "#1E3A8A", marginLeft: "5px", cursor: "pointer" }} />}
          position="top center"
        />
      </>
    );
  }

 getItemWithTooltip(fieldName, altTitleText, altInfoText, htmlContent = false) {
    return htmlContent ? (
      <div style={{ display: "inline-flex", alignItems: "center" }}>
        <Popup
          content={ this.getContent(fieldName, "tooltip", altInfoText)}
          trigger={<div dangerouslySetInnerHTML={ this.getContent(fieldName, "item", altTitleText, htmlContent)} />}
          position="top center"
        />
      </div>
    ) : (
      <div style={{ display: "inline-flex", alignItems: "center" }}>
        <Popup
          content={ this.getContent(fieldName, "tooltip", altInfoText)}
          trigger={<div>{ this.getContent(fieldName, "item", altTitleText)}</div>}
          position="top center"
        />
      </div>
    );
  }

  getItemWithIconTooltip(fieldName, altTitleText, altInfoText, iconName ,htmlContent = false) {
    return htmlContent ? (
      <div style={{ display: "inline-flex", alignItems: "center" }}>
      <Popup
        content={ this.getContent(fieldName, "tooltip", altInfoText)}
        trigger={
          <div>
            <Icon
              name={iconName}
              className="circular"
              size="small"
              style={{ color: "#1E3A8A", marginLeft: "5px", cursor: "pointer" }}
            />
            <span
              dangerouslySetInnerHTML={ this.getContent(fieldName, "item", altTitleText, htmlContent)}
            />
          </div>
        }
        position="top center"
      />
    </div>
    ) : (
      <>
        <Popup
          content={this.getContent(fieldName, "tooltip", altInfoText)}
          trigger={<div>
            {<Icon name={iconName} className="circular" size="small" style={{ color: "#1E3A8A", marginLeft: "5px", cursor: "pointer" }} />}
            {this.getContent(fieldName, "item", altTitleText)}</div>}
          position="top center"
        />
      </>
    );
  }
}



export default ContentService;
