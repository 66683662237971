import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";

class BuildingMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="ui top attached inverted small menu">
        <Link
          to={"/building/" + this.props.match.params.buildingId}
          className={
            (this.props.match.path === "/building/:buildingId"
              ? "active"
              : "") + " item"
          }
        >
          <Icon name="block layout" />
          Dashboard
        </Link>
        <Link
          to={"/building/activities/" + this.props.match.params.buildingId}
          className={
            (this.props.match.path === "/building/activities/:buildingId"
              ? "active"
              : "") + " item"
          }
        >
          <Icon name="treatment" /> Activity Monitor
        </Link>
        <div className="ui simple dropdown item">
          <Icon name="home" /> KNX Project
          <Icon name="dropdown" />
          <div className="menu">
            <Link
              to={"/building/groups/" + this.props.match.params.buildingId}
              className={
                (this.props.match.path === "/building/groups/:buildingId"
                  ? "active"
                  : "") + " item"
              }
            >
              Group Addresses
            </Link>
            <Link
              to={"/building/devices/" + this.props.match.params.buildingId}
              className={
                (this.props.match.path === "/building/devices/:buildingId"
                  ? "active"
                  : "") + " item"
              }
            >
              Building View
            </Link>
          </div>
        </div>
        <Link
          to={"/building/alerts/" + this.props.match.params.buildingId}
          className={
            (this.props.match.path === "/building/alerts/:buildingId"
              ? "active"
              : "") + " item"
          }
        >
          <Icon name="announcement" />
          Alerts
        </Link>
        <Link
          to={"/building/timers/" + this.props.match.params.buildingId}
          className={
            (this.props.match.path === "/building/timers/:buildingId"
              ? "active"
              : "") + " item"
          }
        >
          <Icon name="wait" />
          Timers
        </Link>
        <Link
          to={"/building/settings/" + this.props.match.params.buildingId}
          className={
            (this.props.match.path === "/building/settings/:buildingId"
              ? "active"
              : "") + " item"
          }
        >
          <Icon name="cog" />
          Settings
        </Link>

        {this.props.children}
      </div>
    );
  }
}
export default BuildingMenu;
