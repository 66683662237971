import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";

import { api } from "./api";
import "./styles/default.css";

import TopBar from "./components/TopBar/TopBar";
import Buildings from "./pages/Buildings";

import BuildingDashboard from "./pages/BuildingDashboard";
import BuildingActivities from "./pages/BuildingActivities";
import BuildingGroups from "./pages/BuildingGroups";
import BuildingDevices from "./pages/BuildingDevices";
import BuildingSettings from "./pages/BuildingSettings";
import BuildingAlerts from "./pages/BuildingAlerts";
import BuildingTimers from "./pages/BuildingTimers";
import Subscription from "./pages/Subscription";
import Login from "./pages/Login";
import PasswordReset from "./pages/PasswordReset";

const App = () => {
  const [buildingId, setBuildingId] = useState(undefined);
  const [building, setBuilding] = useState(undefined);
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    // Check access token
    const storageAvailable = (type) => {
      try {
        var storage = window[type],
          x = "__storage_test__";
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
      } catch (e) {
        return false;
      }
    };

    if (storageAvailable("localStorage")) {
      const accessToken = localStorage.getItem("accessToken");
      if (accessToken) {
        window.accessToken = accessToken;
        setLoggedIn(true);
      }
    }
  }, []);

  useEffect(() => {

    if (loggedIn) {

    if (buildingId) {
      api("/building/basic/" + buildingId).then((res) => {
        // console.log(res);
        setBuilding(res);
      });
    } else {
      setBuilding(undefined);
    }
  }
  }, [loggedIn, buildingId]);

  return (
    <>
      {loggedIn ? (
        <>
          <TopBar
            building={building}
            setLoggedIn={setLoggedIn}
            loggedIn={loggedIn}
          />
          <div className="app_content">
            <Route
              path="/buildings/new"
              render={({ match, history }) => (
                <BuildingSettings
                  match={match}
                  history={history}
                  setBuildingId={setBuildingId}
                />
              )}
            />
            <Route
              exact={true}
              path="/building/:buildingId"
              render={({ match }) => (
                <BuildingDashboard
                  match={match}
                  setBuildingId={setBuildingId}
                />
              )}
            />
            <Route
              path="/building/activities/:buildingId"
              render={({ match }) => (
                <BuildingActivities
                  match={match}
                  setBuildingId={setBuildingId}
                />
              )}
            />
            <Route
              path="/building/groups/:buildingId"
              render={({ match }) => (
                <BuildingGroups match={match} setBuildingId={setBuildingId} />
              )}
            />
            <Route
              path="/building/devices/:buildingId"
              render={({ match }) => (
                <BuildingDevices match={match} setBuildingId={setBuildingId} />
              )}
            />
            <Route
              path="/building/settings/:buildingId"
              render={({ match, history }) => (
                <BuildingSettings
                  match={match}
                  history={history}
                  setBuildingId={setBuildingId}
                />
              )}
            />
            <Route
              path="/building/alerts/:buildingId"
              render={({ match }) => (
                <BuildingAlerts match={match} setBuildingId={setBuildingId} />
              )}
            />
            <Route
              path="/building/timers/:buildingId"
              render={({ match }) => (
                <BuildingTimers match={match} setBuildingId={setBuildingId} />
              )}
            />
            <Route path="/subscription" component={Subscription} />
            <Route path="/buildings" exact={true} component={Buildings} />
            <Route path="/" exact={true} component={Buildings} />
          </div>
        </>
      ) : (
        <Switch>
          <Route path="/password-reset" component={PasswordReset} />
          <Route path="/" render={() => <Login setLoggedIn={setLoggedIn} />} />
        </Switch>
      )}
    </>
  );
};

export default App;
