import React from "react";
import { loadStripe } from "@stripe/stripe-js/pure";
import { Icon } from "semantic-ui-react";
import { api } from "../api";

const SubscriptionOption = ({
  name,
  max_active_buildings,
  max_timers,
  max_alerts,
  prices,
  hasStripe,
  isCurrentPlan,
  free_trial_days,
  user,
  contentService,
}) => {


  const getCustomerPortalUrl = () => {
    api("/payments/customer-portal", { method: "POST" }).then(
      (data) => (window.location.href = data.url)
    );
  };
  
  const getCustomerUpdateUrl = () => {
    api("/payments/update-subscription", { method: "POST" }).then(
      (data) => (window.location.href = data.url)
    );
  };
 

  const getCheckoutSessionUrl = async (price_id) => {
    const stripeDetails = await api("/payments/stripe");

    /* Only load stripe.js when we hit the checkout button, otherwise it sends telemetry to Stripe every time you change
    page or move the mouse for "fraud detection".
    See https://mtlynch.io/stripe-recording-its-customers/
    and https://stripe.com/docs/disputes/prevention/advanced-fraud-detection#disabling-advanced-fraud-detection
    - we use the /pure version of the js library so that Stripe doesn't start tracking all the page views and mouse 
    movements until the customer presses the checkout button and reaches this point in the code. 
    Tracking might continue after the customer has checked out unless you uncomment the next line too.
    */
    // loadStripe.setLoadParameters({advancedFraudSignals: false});
    const stripe = await loadStripe(stripeDetails.publishable_key);

    const response = await api("/payments/create-checkout-session", {
      method: "POST",
      body: JSON.stringify({ price_id: price_id }),
    });

    // Error silently if it can't get a session id from stripe
    if (response.sessionId === undefined) {
      return;
    }

    await stripe.redirectToCheckout({ sessionId: response.sessionId });
  };

  return (
    <div className="pricing-container">
      <div className="subscription-name">{name}</div>

      {hasStripe && (
        <div className="price">
          <div className="price-figure">
            {prices.map(({ price, tenure, metadata }) => (
              <div key={price} title={metadata.price_info}>
                <span className="price-number">{price}</span>
                <span className="price-tenure">{tenure}</span>
              </div>
            ))}
          </div>
        </div>
      )}
      <ul className="features">
        <li>
          <Icon name="copy outline" />
          {contentService.getItemWithTooltip('number-projects')}
        </li>
        <li >
          {max_active_buildings === 1 ? <Icon name="home" /> : <Icon name="building outline" />}
          {max_active_buildings}{" "}
          {max_active_buildings === 1 ? contentService.getItemWithTooltip('number-active-buildings-single') 
          : contentService.getItemWithTooltip('number-active-buildings')} 
        </li>
        <li>
          <Icon name="bell outline" />
          {max_alerts === 0
            ? contentService.getItemWithTooltip('alerts-none')//,`No Alerts`,`These are email alerts you can configure based on logic` )
            : max_alerts === null
            ? contentService.getItemWithTooltip('alerts-unlimited')//,`Unlimited Alerts`,`These are email alerts you can configure based on logic` ) 
            : max_alerts === 1
            ? contentService.getItemWithTooltip('alerts-one')//,`1 Alert per Building`,`These are email alerts you can configure based on logic` )
            : contentService.getItemWithTooltip('alerts-n',`${max_alerts} Alerts per Building`,`These are email alerts you can configure based on logic`)}
        </li>
        <li>
          <Icon name="clock outline" />
          {max_timers === 0
            ? contentService.getItemWithTooltip('timers-none')//,`No Timers`,`Timers can be absolute or offset to Sunrise or Sunset. Daylight saving is automatically applied` )
            : max_timers === null
            ? contentService.getItemWithTooltip('timers-unlimited')
            : max_timers === 1
            ? contentService.getItemWithTooltip('timers-one')
            : contentService.getItemWithTooltip('timers-n',`${max_alerts} Timers per Building`)}
        </li>
        <li>
          <Icon name="envelope outline" />
          {contentService.getItemWithTooltip('support-email')}
        </li>
      </ul>
      <div className="footer">
        {free_trial_days > 0 && !hasStripe && !isCurrentPlan && (
          <div className="free-trial-list-item">
            {free_trial_days} {contentService.getContent('free-trial','text', `days free trial`)} 
          </div>
        )}
        {user.has_stripe && name !== user.subscription_plan_name && (
          <button
            className="btn btn-inverse btn-block"
            onClick={() => getCustomerUpdateUrl()}>
            {contentService.getContent('manage-plan-upgrade','button-text')}
          </button>
        )}
        {user.has_stripe && name === user.subscription_plan_name && (
          <button
            className="btn btn-inverse btn-block"
            style={{ color: "#f5ce42"}} 
            onClick={() => getCustomerPortalUrl()}>
            <Icon name="user outline" />
            {contentService.getContent('manage-plan-current','button-text')}
          </button>
        )}
        {!hasStripe &&
          !isCurrentPlan &&
          prices.map(({ price, tenure, id }) => (
            <button
              className="btn btn-inverse btn-block"
              onClick={() => getCheckoutSessionUrl(id)}
              key={price}
            >
              {`${price} ${tenure ? tenure : ""}`}
            </button>
          ))}
      </div>
    </div>
  );
};

export default SubscriptionOption;
