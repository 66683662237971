import React, {useEffect, useState , useRef } from "react";
import { Icon } from "semantic-ui-react";
import { api } from "../../api";
import ContentService from "../../content/contentService";

const DeleteBuilding = ({
  buildingName,
  buildingID,
  routerHistory,
  setBuildingId,
}) => {
  const [deleteButtonDisabled, disableDeleteButton] = useState(true);
  const [deleteInProgress, setDeleteInProgress] = useState(false);

  const contentService = useRef(new ContentService("delete-settings"));

  // Content loading state
  const [contentLoaded, setContentLoaded] = useState(false);

  // Load content on component mount
  useEffect(() => {
    contentService.current.loadContent().then(() => {
      setContentLoaded(true);
    });
  }, []); // Only run once on mount

  if (!contentLoaded) {
    return <div className="ui active small inline loader" />;
  }

  return (
    <>
      <h3 className="ui top attached header">
        <Icon name="trash" />
        <div className="content">
          {contentService.current.getContent('delete-building','title')}
          <span className="sub header">
            {contentService.current.getContent('delete-building','sub-heading')}
          </span>
        </div>
      </h3>
      <div className="ui attached segment">
        <div className="ui form">
          <div className="fields">
            <div className="eight wide field">
              {deleteInProgress ? (
                <p>{contentService.current.getContent('delete-building-progress','info')}</p>
              ) : (
                <label>
                  {contentService.current.getContent('delete-building-warning','warning')}
                  <input
                    type="text"
                    placeholder=""
                    style={{ display: 'block', marginTop: '15px' }}
                    onChange={(e) => {
                      e.target.value === buildingName
                        ? disableDeleteButton(false)
                        : disableDeleteButton(true);
                    }}
                  />
                </label>
              ) }
              <button
                className="ui negative button"
                disabled={deleteButtonDisabled}
                style={{width: "150px", display: 'block', marginTop: '10px'}}
                onClick={() => {
                  disableDeleteButton(true);
                  setDeleteInProgress(true);
                  api("/building/delete/" + buildingID, {
                    method: "POST",
                  }).then(() => {
                    setBuildingId(undefined);
                    routerHistory.push("/buildings");
                  });
                }}
              > <Icon name='trash' />
                {contentService.current.getContent('delete-building-button','button-text')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteBuilding;
