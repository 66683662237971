// Terminal.js
import React, { Component, createRef } from "react";

class Terminal extends Component {
  constructor(props) {
    super(props);
    this.terminalRef = createRef();
    this.state = {
      terminalLogs: [],
    };
    // this.terminalRef = createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.newMessage !== this.props.newMessage) {
      this.addLog(this.props.newMessage);
    }
    this.scrollToBottom();
  }

  addLog = (newMessage) => {
    const { source, status, message } = newMessage;
    const formattedLog = this.formatMultiLineStatus(source, status, message);

    this.setState(
      (prevState) => ({
        terminalLogs: [...prevState.terminalLogs, formattedLog],
      })
    );
  };

  scrollToBottom() {
    this.terminalRef.current.scrollTop = this.terminalRef.current.scrollHeight;
  }

  formatMultiLineStatus(source, status, payload) {
    return (
      <div>
        <span>{source}: <span style={{ color: "red" }}>{status}</span> = </span>
        {this.renderPayload(payload)}
      </div>
    );
  }

  renderPayload(payload) {
    if (typeof payload === "string") {
      return <span>{payload}</span>;
    } else if (typeof payload === "object" && payload !== null) {
      return Object.keys(payload).map((key, index) => (
        <span key={index}>
          {key}:{" "}
          {typeof payload[key] === "object" ? (
            this.renderPayload(payload[key])
          ) : (
            <span style={{ color: "orange" }}>{payload[key]}</span>
          )}
          {index < Object.keys(payload).length - 1 && ", "}
        </span>
      ));
    }
    return null;
  }

  render() {
    const { terminalLogs } = this.state;
    return (
      <div ref={this.terminalRef} className="terminal">
        <div>
          console:&gt; <span className="blinkingCaret">_</span>
        </div>
        {terminalLogs.length > 0
          ? terminalLogs.map((log, index) => <div key={index}>{log}</div>)
          : <p></p>
        }
      </div>
    );
  }
}

export default Terminal;
