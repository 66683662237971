import "whatwg-fetch";

import { io } from "socket.io-client";

// API Wrapper

const API_PATH = "/api";
const WS_PATH = "/ws";

const api = (url, _opts) => {
  let opts = _opts || {};
  opts.headers = opts.headers || {};

  if (url !== "/authenticate") {
    opts.headers["x-access-token"] = window.accessToken;
    if (
      opts.method &&
      (opts.method.toLowerCase() === "post" ||
        opts.method.toLowerCase() === "put")
    )
      opts.headers["Content-Type"] = "application/json";
  }

  // console.log(window.location.origin + API_PATH + url);

  return fetch(window.location.origin + API_PATH + url, opts).then(checkStatus);
};

const ws = () => {
  return new Promise((resolve, reject) => {
    let opts = {
      query: "accessToken=" + window.accessToken,
      transports: ["websocket"],
      path: WS_PATH,
    };
    let socket = io.connect(window.location.origin, opts);
    socket.once("connect", () => {
      resolve(socket);
    });
    socket.once("error", function (e) {
      reject(e);
    });
  });
};

// Check status response
const checkStatus = (response) => {
  if (response.status === 401) {
    // Redirect to root page, which will be login screen if they are not logged in
    window.location = "/";
    localStorage.removeItem("accessToken");
    if (window.accessToken) {
      delete window.accessToken;
    }
    return;
  }
  return response.json();
};

export { api, ws };
