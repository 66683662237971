import React, { useEffect, useState, useRef } from "react";
import Modal from "react-modal";
import { Icon, Checkbox } from "semantic-ui-react";

import { api } from "../api";
import ContentService from "../content/contentService";
import GroupComboBox from "../components/GroupComboBox";
import BuildingMenu from "../components/BuildingMenu";
import * as DPT from "../components/DPTComboBox/dpt";
import DPTControls from "../components/BuildingGroupPanel/controls";
import CronTimer from "../components/CronTimer";

const modalStyles = {
  content: {
    background: "transparent",
    border: "none",
  },
};

const BuildingTimers = (props) => {
  const [timers, setTimers] = useState();
  const [closed, setClosed] = useState(false);
  const [groups, setGroups] = useState();
  const [editingTimer, setEditingTimer] = useState();
  const [errorMessage, setErrorMessage] = useState("");

  const contentService = useRef(new ContentService("timers"));
  const [contentLoaded, setContentLoaded] = useState(false);

  const { setBuildingId, match } = props;
  const buildingId = match.params.buildingId;

  useEffect(() => {
    if(!contentLoaded){
      contentService.current.loadContent().then(() => {
        setContentLoaded(true);
      });
      setBuildingId(buildingId);
      Modal.setAppElement("body");
      fetchBuildingGroups(() => {
        fetchBuildingTimers(() => {});
      });
    }
    return () => setClosed(true);

  },  [contentLoaded,
      setBuildingId, 
      buildingId]);
 
  const enableTimer = (checked, timer) => {
    api("/building/" + buildingId + "/timers/" + timer._id, {
      method: "POST",
      body: JSON.stringify({
        enabled: checked,
      }),
    }).then((res) => {
      if (res.success) {
        setErrorMessage("");
      } else if (res.error) {
        setErrorMessage(res.error);
      }
    });
  };

  const fetchBuildingGroups = (cb) => {
    api("/building/group/" + buildingId).then((res) => {
      if (closed) return;
      if (res.groups) {
        setGroups(res.groups);
      }
      cb();
    });
  };

  const fetchBuildingTimers = (cb) => {
    api("/building/" + buildingId + "/timers").then(
      (res) => {
        if (res.results) {
          setTimers(res.results);
        }
        if (cb) cb();
      }
    );
  };

  if (!contentLoaded) {
    return <div className="ui active small inline loader" />;
  }

  if (!timers)
    return (
      <div style={{ background: "#f4f4f4" }}>
        <BuildingMenu {...props} />
        <div
          className="ui loading segment"
          style={{
            border: "none",
            padding: "10em",
            width: "100%",
            height: "calc(100% - 100px)",
            margin: "0 auto",
          }}
        ></div>
      </div>
    );

  return (
    <div style={{ background: "#f4f4f4" }}>
      <BuildingMenu {...props} />
      <div
        className="_page"
        style={{
          border: "none",
          padding: "1em",
          maxWidth: 1200,
          margin: "0 auto",
        }}
      >
      <h3 className="ui top attached header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Icon name="clock outline" style={{ alignSelf: 'stretch', fontSize: '1.6em',marginTop: '10px' , marginRight: '0px' }}  />
          <div className="content" style={{ textAlign: 'left', marginLeft: '10px' }}>
              {contentService.current.getContent('heading', 'title')}
            <span className="sub header">
              {contentService.current.getContent('heading', 'sub-heading')}
            </span>
          </div>
        </div>

        <a href={contentService.current.getContent('btn-information', 'link')} target="_blank" title={contentService.current.getContent('btn-information', 'link-tooltip')} rel="noopener noreferrer" style={{ marginLeft: 'auto' }}>
          <button className="help-button ui blue basic button">
            <Icon name="question circle outline" style={{ alignSelf: 'stretch',marginTop: '-5px' , fontSize: '1.3em'}}/>
            {contentService.current.getContent('btn-information', 'button-text')}
          </button>
        </a>
      </h3>

        {errorMessage ? (
          <div className="ui negative message">{errorMessage}</div>
        ) : null}
        <table className="ui single line selectable celled table">
          <thead>
            <tr>
              <th width={50} style={{ textAlign: "center" }}>
                <Icon name="power" />
              </th>
              <th width="99%">Name</th>
              <th>Timer Cron</th>
              <th>Value Type</th>
              <th>Destination</th>
              <th>Set Value</th>
              <th>
                <button
                  className="ui primary labeled icon button"
                  onClick={() => {
                    setEditingTimer({});
                  }}
                >
                  <Icon name="plus" />
                  New
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {timers.map((timer) => (
              <tr key={timer._id}>
                <td>
                  <Checkbox
                    toggle
                    defaultChecked={timer.enabled}
                    onChange={(e, { checked }) => {
                      enableTimer(checked, timer);
                    }}
                  />
                </td>
                <td>
                  <b>{timer.name}</b>
                </td>
                <td>
                  <span
                    style={{
                      backgroundColor: "rgba(218, 189, 40, 0.15)",
                      color: "#9E6C00",
                    }}
                  >
                    {timer.expression}
                  </span>
                </td>
                <td>
                  <i>{resolveDPTName(timer.dpt)}</i>
                </td>

                <td>
                  <span
                    style={{
                      backgroundColor: "rgba(40, 150, 218, 0.2)",
                      color: "#00689E",
                      padding: "0 0.5em",
                    }}
                  >
                    {groups[timer.group]
                      ? groups[timer.group].name
                      : timer.group}
                  </span>
                </td>
                <td>
                  <span className="ui circular label">
                    {resolveDPTValue(timer.dpt, timer.value)}
                  </span>
                </td>
                <td>
                  <button
                    className="ui button"
                    onClick={() => {
                      setEditingTimer(Object.assign({}, timer));
                    }}
                  >
                    <Icon name="write" />
                    Edit
                  </button>
                </td>
              </tr>
            ))}
            {timers.length === 0 ? (
              <tr key="empty0">
                <td colSpan={4}>No timer set. </td>
              </tr>
            ) : undefined}
          </tbody>
        </table>
      </div>

      <Modal isOpen={editingTimer !== undefined} style={modalStyles}>
        {editingTimer ? (
          <div className="ui workspace modal active" style={{ top: 50 }}>
            <div className="header">
              <div className="ui mini labeled input">
                <div className="ui label">Timer: </div>
                <input
                  type="text"
                  placeholder="name"
                  autoComplete="off"
                  style={{ width: "30em" }}
                  defaultValue={editingTimer.name}
                  onChange={(e) => {
                    setEditingTimer((prevState) => ({
                      ...prevState,
                      name: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>
            <div className="content">
              {/* Timer Widget */}
              <label>
                {" "}
                <b> Timer Set: </b>
              </label>
              <CronTimer
                expression={editingTimer.expression}
                params={props.match.params}
                onChange={(exp) => {
                  setEditingTimer((prevState) => ({
                    ...prevState,
                    expression: exp,
                  }));
                }}
              />

              <div className="ui section divider"></div>

              {/* Timer Action */}
              <div className="content" style={{ paddingLeft: "1em" }}>
                <b>Timer Action: </b>
                <div className="ui horizontal segments">
                  <div className="ui padded segment">
                    <GroupComboBox
                      groups={groups}
                      defaultValue={editingTimer.group}
                      buttonStyle={{
                        display: "none",
                        background: "white",
                        color: "#555",
                        width: "5em",
                        boxShadow: "0 0 0 1px #C1C1C1 inset",
                        height: "2.3em",
                      }}
                      onChange={(groupId) => {
                        setEditingTimer((prevState) => ({
                          ...prevState,
                          group: groupId,
                        }));
                        let group = groups[groupId];
                        if (group && group.dpt) {
                          let dptId = resolveDPTId(group.dpt);
                          if (dptId !== editingTimer.dpt) {
                            setEditingTimer((prevState) => ({
                              ...prevState,
                              dpt: dptId,
                            }));
                          }
                        }
                      }}
                    />
                  </div>
                  <div className="ui padded segment">
                    {editingTimer.dpt && DPT.getById(editingTimer.dpt)
                      ? React.createElement(
                          DPTControls(DPT.getById(editingTimer.dpt)).default,
                          {
                            value: editingTimer.value,
                            onSend: (val) => {
                              // submit changes
                              setEditingTimer((prevState) => ({
                                ...prevState,
                                value: val,
                              }));
                            },
                          }
                        )
                      : "not writable"}
                  </div>
                </div>
              </div>
            </div>
            <div className="actions">
              {/* Delete Action */}
              {editingTimer._id !== undefined ? (
                <button
                  className="ui red left floated labeled icon button"
                  onClick={() => {
                    //delete alert
                    api(
                      "/building/" +
                        props.match.params.buildingId +
                        "/timers/" +
                        editingTimer._id,
                      {
                        method: "DELETE",
                      }
                    ).then(() => {
                      fetchBuildingTimers();
                      setEditingTimer(undefined);
                    });
                  }}
                >
                  <Icon name="trash alternate outline" /> Delete Timer
                </button>
              ) : undefined}
              {/* Save Action */}
              <button
                className={
                  editingTimer.expression &&
                  editingTimer.name &&
                  editingTimer.group &&
                  editingTimer.dpt &&
                  editingTimer.value !== undefined
                    ? "ui positive labeled icon button"
                    : "ui grey disabled labeled icon button"
                }
                onClick={() => {
                  //Save alert
                  //console.log("NEW:", editingTimer);
                  api(
                    "/building/" +
                      props.match.params.buildingId +
                      "/timers" +
                      (editingTimer._id ? "/" + editingTimer._id : ""),
                    {
                      method: editingTimer._id ? "POST" : "PUT",
                      body: JSON.stringify(editingTimer),
                    }
                  ).then((res) => {
                    if (res.success) {
                      fetchBuildingTimers();
                    } else if (res.error) {
                      setErrorMessage(res.error);
                    }
                    setEditingTimer(undefined);
                  });
                }}
              >
                <Icon name="checkmark" /> Save Changes
              </button>
              {/* Close Window Action */}
              <button
                className="ui grey button"
                onClick={() => {
                  setEditingTimer(undefined);
                }}
              >
                Close Window
              </button>
            </div>
          </div>
        ) : undefined}
      </Modal>
    </div>
  );
};

const resolveDPTName = (dpt) => {
  let _dpt = DPT.getById(dpt);
  if (_dpt === undefined) _dpt = DPT.getByName(dpt);
  if (_dpt === undefined) return dpt;

  return _dpt.text;
};
const resolveDPTId = (dpt) => {
  let _dpt = DPT.getById(dpt);
  if (_dpt === undefined) _dpt = DPT.getByName(dpt);
  if (_dpt === undefined) return dpt;

  return _dpt.id;
};
const resolveDPTValue = (dpt, value) => {
  let _dpt = DPT.getById(dpt);
  if (_dpt === undefined) _dpt = DPT.getByName(dpt);
  if (_dpt === undefined || _dpt.formatter === undefined) return value;

  return _dpt.formatter(value);
};

export default BuildingTimers;
