import React from "react";
import BuildingMenu from "../components/BuildingMenu";
import moment from "moment";
import { Icon } from "semantic-ui-react";

import _ from "underscore";
import * as d3 from "d3";
import MG from "metrics-graphics";
import "metrics-graphics/dist/metricsgraphics.css";
import Modal from "react-modal";

import { api } from "../api";
import DateRangePicker from "../components/DateRangePicker/DateRangePicker";
import GroupComboBox from "../components/GroupComboBox";
import * as DPT from "../components/DPTComboBox/dpt";

const colors = [
  "#6ED0E0",
  "#7EB26D",
  "#EAB839",
  "#EF843C",
  "#E24D42",
  "#1F78C1",
  "#BA43A9",
  "#705DA0", //1
  "#508642",
  "#CCA300",
  "#447EBC",
  "#C15C17",
  "#890F02",
  "#0A437C",
  "#6D1F62",
  "#584477", //2
  "#B7DBAB",
  "#F4D598",
  "#70DBED",
  "#F9BA8F",
  "#F29191",
  "#82B5D8",
  "#E5A8E2",
  "#AEA2E0", //3
  "#629E51",
  "#E5AC0E",
  "#64B0C8",
  "#E0752D",
  "#BF1B00",
  "#0A50A1",
  "#962D82",
  "#614D93", //4
  "#9AC48A",
  "#F2C96D",
  "#65C5DB",
  "#F9934E",
  "#EA6460",
  "#5195CE",
  "#D683CE",
  "#806EB7", //5
  "#3F6833",
  "#967302",
  "#2F575E",
  "#99440A",
  "#58140C",
  "#052B51",
  "#511749",
  "#3F2B5B", //6
  "#E0F9D7",
  "#FCEACA",
  "#CFFAFF",
  "#F9E2D2",
  "#FCE2DE",
  "#BADFF4",
  "#F9D9F9",
  "#DEDAF7", //7
];

// Data decoded with one of these "converters" needs to be put in the nonNumericGroups list when you do a time series query
// TODO: duplicated in BuildingGroupPanel.js
const nonNumericConverters = [
  "DPTBoolean",
  "DPT1BitControlled",
  "DPT3BitControlled",
  "DPTTime",
  "DPTDate",
  "DPTDateTime",
  "DPTRGB",
  "DPTString",
  "DPTSceneControl",
];

const modalStyles = {
  content: {
    // top                   : 'auto',
    // left                  : 'auto',
    // right                 : 'auto',
    // bottom                : 'auto',
    // marginRight           : '-50%',
    // transform             : 'translate(-50%, -50%)'
    background: "transparent",
    border: "none",
  },
};

class BuildingDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editingWorkspace: undefined,
      chartSize: "small",
    };
    this.props.setBuildingId(this.props.match.params.buildingId);
  }

  componentDidMount() {
    Modal.setAppElement("body");
    setTimeout(() => {
      this.fetchBuildingGroups(() => {
        this.fetchWorkspaces(() => {
          //remove loading animation
          this.setLoading(false);
        });
      });
    }, 500);
  }
  componentWillUnmount() {
    this.closed = true;
    if (this.socket) this.socket.close();
  }

  setLoading(isLoading) {
    var loadingEles = document.querySelectorAll("._page.segment");
    if (loadingEles)
      Array.prototype.forEach.call(loadingEles, (ele) => {
        if (isLoading === true) ele.classList.add("loading");
        else ele.classList.remove("loading");
      });
  }

  postNewState = () => {
    let newWorkspace = this.state.editingWorkspace;

    api("/building/workspaces/" + this.props.match.params.buildingId, {
      method: "POST",
      body: JSON.stringify(this.state.workspaces),
    });
    this.setState(
      {
        editingWorkspace: undefined,
        workspaces: this.state.workspaces,
      },
      () => {
        this.refreshWorkspaceChart(newWorkspace);
      }
    );
  };

  fetchBuildingGroups(cb) {
    api("/building/group/" + this.props.match.params.buildingId).then((res) => {
      if (this.closed === true) return;
      if (res.groups) {
        for (var gAdd in res.groups) {
          let group = res.groups[gAdd];
          let dpt = DPT.resolveDPT(group.dpt);
          if (dpt !== undefined) group._dpt = dpt;
        }
        this.setState({ groups: res.groups }, () => {
          cb();
        });
      } else cb();
    });
  }
  fetchWorkspaces(cb) {
    api("/building/workspace/" + this.props.match.params.buildingId).then(
      (res) => {
        if (this.closed === true) return;
        if (res.workspaces)
          this.setState({ workspaces: res.workspaces }, () => {
            cb();

            //render charts
            this.refreshAllWorkspaces();
          });
        else cb();
      }
    );
  }

  toggleChartSize = () => {
    const newChartSize = this.state.chartSize === "small" ? "large" : "small";
    this.setState({ chartSize: newChartSize });
    // console.log("new chart size ", newChartSize);
    this.refreshAllWorkspaces(newChartSize);
  };

  refreshAllWorkspaces(newChartSize) {
    const tasks = this.state.workspaces.map((workspace) =>
      this.refreshWorkspaceChart(workspace, newChartSize)
    );
    Promise.all(tasks);
  }

  refreshWorkspaceChart(workspace, newChartSize) {
 
    // console.log('refreshWorkspaceChart', newChartSize);

    if (!newChartSize){
      newChartSize = "small"
    }
    const chartHeight = newChartSize === "small" ?  220 : 400 ;
    // console.log(newChartSize ,chartHeight);

    return new Promise((resolve) => {
      if (workspace.groups === undefined || workspace.groups.length <= 0) {
        return resolve();
      }
      //load time series
      const payload = {
        groups: workspace.groups,
        nonNumericGroups: [],
      };

      for (let i = 0; i < workspace.groups.length; i++) {
        if (
          Object.prototype.hasOwnProperty.call(
            this.state.groups,
            workspace.groups[i]
          )
        ) {
          let grp = this.state.groups[workspace.groups[i]];
          if (grp._dpt && nonNumericConverters.includes(grp._dpt.converter))
            payload.nonNumericGroups.push(workspace.groups[i]);
        }
      }
      if (payload.nonNumericGroups.length === 0) {
        delete payload.nonNumericGroups;
      }

      if (this.startTime) {
        payload.start = this.startTime;
      }
      if (this.endTime) {
        payload.end = this.endTime;
      }

      document
        .querySelectorAll(
          "#WorkspaceChart" + workspace.id + " .description .loader"
        )[0]
        .classList.add("active");
      api("/building/ts/groups/" + this.props.match.params.buildingId, {
        method: "POST",
        body: JSON.stringify(payload),
      })
        .then((res) => {
          document
            .querySelectorAll(
              "#WorkspaceChart" + workspace.id + " .description .loader"
            )[0]
            .classList.remove("active");
          if (this.closed === true) {
            return;
          }
          resolve();
          if (res.success) {
            const data = res.results.map((dg) =>
              dg.map((dgt) => ({
                ...dgt,
                time: moment.utc(dgt.time).toDate(),
              }))
            );
            data.push([]); // fix for aggregate_rollover
            const legends = workspace.groups.map((g) => {
              if (Object.prototype.hasOwnProperty.call(this.state.groups, g)) {
                if (this.state.groups[g].alias) {
                  return this.state.groups[g].alias;
                }
                return this.state.groups[g].name;
              }
              return g;
            });
            const plotData =
              !data[0].length || isNaN(data[0][0].v) ? [[]] : data;
            //render chart
            MG.data_graphic({
              title: "",
              full_width: true,
              // full_height: true,
              height: chartHeight,
              data: plotData,
              buffer: 0,
              top: 15,
              left: 30,
              target: this["WorkspaceChart" + workspace.id],
              x_accessor: "time",
              y_accessor: "v",
              // brush: "y",
              // axes_not_compact: false,
              // y_extended_ticks: true,
              // x_extended_ticks: true,
              min_y_from_data: true,
              interpolate: "step-after",
              chart_type: plotData[0].length ? "line" : "missing-data",
              missing_is_zero: true,
              show_secondary_x_label: false,
              small_text: true,
              colors: colors,
              legend: legends,
              legend_target: "div#WorkspaceChart" + workspace.id + " .legends",
              aggregate_rollover: true,
              show_tooltips: false,
              mouseover: (d) => {
                //format time
                d3.select(
                  "div#WorkspaceChart" +
                    workspace.id +
                    " svg .mg-active-datapoint tspan"
                ).text(moment(d.key).format("DD-MM-YYYY HH:mm:ss"));
                //format legends
                d3.selectAll(
                  `div#WorkspaceChart${workspace.id} svg .mg-active-datapoint tspan[x='0'] tspan:first-child`
                )
                  .attr("stroke", null)
                  .property("style", (_, i) => {
                    return (
                      "text-shadow: 0 0 3px black; fill:" +
                      colors[d.values[i].line_id - 1]
                    );
                  })
                  .text((_, i) => {
                    return legends[d.values[i].line_id - 1] + " ";
                  });
              },
            });
          }
        })
        .catch(() => {
          // document.querySelectorAll('#WorkspaceChart'+workspace.id + ' .description .loader')[0].classList.remove("active")
          resolve(); // continue to next workspace
        });
    });
  }

  render() {
    const { chartSize } = this.state;
    const isLargeChart = chartSize === "large";
    const chartHt = isLargeChart ? 400 : 220;

    // console.log("render", chartSize, isLargeChart, chartHt);

    let workspaces = undefined;
    if (this.state.workspaces)
      workspaces = (
        <div className={`ui centered grid`}>
          
        {/* <div className="ui centered grid"> */}
          {this.state.workspaces.map((workspace) => (
            <div
              className={`sixteen wide eight wide tablet ${isLargeChart ? 'eight' : 'four'} wide computer column`}
              key={workspace.id}
            >
              <div
                className="ui fluid workspace card"
                id={"WorkspaceChart" + workspace.id}
              >
                <div className="image">
                  <div
                    className="workspace_chart"
                    style={{ height: chartHt }}
                    ref={(ref) => (this["WorkspaceChart" + workspace.id] = ref)}
                  ></div>
                </div>
                <div className="content">
                  <div className="description">
                    {workspace.text}
                    <div className="ui mini inline loader"></div>
                    <button
                      className="right floated star"
                      onClick={() => {
                        this.setState({
                          editingWorkspace: JSON.parse(
                            JSON.stringify(workspace)
                          ),
                        });
                      }}
                    >
                      <Icon name="write" />
                      Edit
                    </button>
                  </div>
                  <div className="legends"></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      );

    return (
      <div style={{ background: "#f4f4f4" }}>
        <BuildingMenu {...this.props} />

        <div
          className="ui _page loading segment"
          style={{ margin: 0, border: "none" }}
        >
          <div className="ui top attached menu">
            <div className="active item">
              <Icon name="desktop" />
              Graphs
            </div>
            <div className="right menu">
              <div className="item">
                <button
                  className="ui primary labeled icon button"
                  onClick={() => {
                    this.setState({ editingWorkspace: { groups: [] } });
                  }}
                >
                  <Icon name="plus" />
                  Graph
                </button>
              </div>
              <div className="active item">
                <Icon name="calendar alternate outline" />
                <div className="ui small input">
                  <DateRangePicker
                    options={{
                      opens: "left",
                      timePicker: true,
                      locale: { format: "DD-MM-YYYY" },
                    }}
                    onApply={(start, end) => {
                      this.startTime = start.toDate();
                      this.endTime = end.toDate();
                      this.refreshAllWorkspaces(chartSize);
                    }}
                  />
                </div>
              </div>
              <div className="active item">
                <button
                    className="ui primary labeled icon button"
                    onClick={this.toggleChartSize}
                >
                  <Icon name={isLargeChart ? 'compress' : 'expand'} />
                  {isLargeChart ? 'Shrink Charts' : 'Enlarge Charts'}
                </button>
              </div>
            </div>
          </div>
          <div
            className="ui bottom attached segment"
            style={{ background: "#f4f4f4" }}
          >
            {workspaces}
          </div>
        </div>

        <Modal
          isOpen={this.state.editingWorkspace !== undefined}
          style={modalStyles}
        >
          {this.state.editingWorkspace ? (
            <div className="ui workspace modal active" style={{ top: 50 }}>
              <div className="header">
                <div className="ui mini labeled input">
                  <div className="ui label">graph: </div>
                  <input
                    type="text"
                    placeholder="name"
                    autoComplete="off"
                    style={{ width: "20em" }}
                    value={this.state.editingWorkspace.text}
                    onChange={(event) => {
                      this.setState((prevState) => ({
                        editingWorkspace: {
                          ...prevState.editingWorkspace,
                          text: event.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
              <div className="content">
                <div className="ui middle aligned bulleted list">
                  {this.state.editingWorkspace.groups &&
                  this.state.editingWorkspace.groups.length > 0
                    ? this.state.editingWorkspace.groups.map((g) => {
                        let name = g;
                        if (
                          Object.prototype.hasOwnProperty.call(
                            this.state.groups,
                            g
                          )
                        ) {
                          name = this.state.groups[g].name;
                          if (this.state.groups[g].alias)
                            name += " (" + this.state.groups[g].alias + ")";
                        }
                        return (
                          <div className="item" key={g}>
                            <div className="right floated content">
                              <button
                                className="ui mini button"
                                style={{ color: "red" }}
                                onClick={() => {
                                  this.state.editingWorkspace.groups.splice(
                                    this.state.editingWorkspace.groups.indexOf(
                                      g
                                    ),
                                    1
                                  );
                                  this.setState({
                                    editingWorkspace: Object.assign(
                                      {},
                                      this.state.editingWorkspace
                                    ),
                                  });
                                }}
                              >
                                Remove
                              </button>
                            </div>
                            <div className="content">
                              <div className="ui label">
                                {g}
                                <div className="detail">{name}</div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : undefined}
                </div>
                <div className="content">
                  <GroupComboBox
                    groups={Object.fromEntries(
                      Object.entries(this.state.groups).filter(
                        (entry) =>
                          this.state.editingWorkspace.groups.indexOf(
                            entry[0]
                          ) === -1
                      )
                    )}
                    onSelect={(group_id) => {
                      if (
                        this.state.editingWorkspace.groups.indexOf(group_id) >
                        -1
                      )
                        return;
                      this.state.editingWorkspace.groups.push(group_id);
                      this.setState({
                        editingWorkspace: Object.assign(
                          {},
                          this.state.editingWorkspace
                        ),
                      });
                    }}
                  />
                </div>
              </div>
              <div className="actions">
                {this.state.editingWorkspace.id !== undefined ? (
                  <button
                    className="ui red left floated labeled icon button"
                    onClick={() => {
                      //delete workspace
                      let newWorkspaces = _.reject(
                        this.state.workspaces,
                        (w) => w.id === this.state.editingWorkspace.id
                      );

                      api(
                        "/building/workspaces/" +
                          this.props.match.params.buildingId,
                        {
                          method: "POST",
                          body: JSON.stringify(newWorkspaces),
                        }
                      );
                      this.setState({
                        editingWorkspace: undefined,
                        workspaces: newWorkspaces,
                      });
                    }}
                  >
                    <Icon name="trash alternate outline" /> Delete Graph
                  </button>
                ) : undefined}
                <button
                  className={
                    this.state.editingWorkspace.text &&
                    this.state.editingWorkspace.groups.length >= 1
                      ? "ui positive labeled icon button"
                      : "ui grey disabled labeled icon button"
                  }
                  onClick={() => {
                    //Save Workspace
                    if (this.state.editingWorkspace.id === undefined) {
                      let gid = Math.max(
                        _.max(this.state.workspaces, (w) => w.id).id,
                        0
                      );
                      gid = isFinite(gid) ? gid : 0;

                      const newState = {
                        editingWorkspace: {
                          ...this.state.editingWorkspace,
                          id: gid + 1,
                        },
                        workspaces:
                          this.state.workspaces === undefined
                            ? [
                                {
                                  ...this.state.editingWorkspace,
                                  id: gid + 1,
                                },
                              ]
                            : [
                                {
                                  ...this.state.editingWorkspace,
                                  id: gid + 1,
                                },
                                ...this.state.workspaces,
                              ],
                      };
                      this.setState(newState, () => this.postNewState());
                    } else {
                      const newState = this.state.workspaces.map((workspace) =>
                        workspace.id === this.state.editingWorkspace.id
                          ? this.state.editingWorkspace
                          : workspace
                      );
                      this.setState({ workspaces: newState }, () =>
                        this.postNewState()
                      );
                    }
                  }}
                >
                  <Icon name="checkmark" /> Save Changes{" "}
                </button>
                <button
                  className="ui grey button"
                  onClick={() => {
                    this.setState({ editingWorkspace: undefined });
                  }}
                >
                  Close Window
                </button>
              </div>
            </div>
          ) : undefined}
        </Modal>
      </div>
    );
  }
}

export default BuildingDashboard;
