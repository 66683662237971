import React, { useEffect, useRef } from 'react';

// Component replaces use-places-autocomplete which doesn't support the new api format.

const AutocompleteInput = ({ onPlaceSelected, position, contentService }) => {
  const inputRef = useRef(null);

  // console.log(`AutoComplete`)

  useEffect(() => {
    if (!window.google) {
      console.error('Google Maps JavaScript API library must be loaded.');
      return;
    }

    const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, {
      fields: ['place_id', 'geometry', 'name'],
      locationBias: new window.google.maps.Circle({
        center: { lat: position.lat, lng: position.lng },
        radius: 200000,
      }),
    });

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        const { lat, lng } = place.geometry.location;
        onPlaceSelected({ lat: lat(), lng: lng() });
      } else {
        console.error('No details available for input: ' + place.name);
      }
    });

    return () => {
      window.google.maps.event.clearInstanceListeners(autocomplete);
    };
  }, [onPlaceSelected, position]);

  return (
    <div className="search" style={{ display: 'block', marginTop: '8px' }}>
      <input
        ref={inputRef}
        type="text"
        placeholder={contentService.current.getContent('location-map-search', 'placeholder')}
      />
    </div>
  );
};

export default AutocompleteInput;
