import React from "react";
import { createRoot } from "react-dom/client";
// import ReactDOM from "react-dom";
import "./styles/default.css";
import "./styles/terminal.css";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import "./jqueryImport";
import "semantic-ui-css/semantic.min.css";
import "./api";
import { BrowserRouter } from "react-router-dom";

const root = createRoot(document.getElementById("root"));

root.render(
  //<React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  //</React.StrictMode>
);


// ReactDOM.render(
//   // <React.StrictMode>
//   <BrowserRouter>
//     <App />
//   </BrowserRouter>,
//   //</React.StrictMode>,
//   document.getElementById("root")
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);


