import React from "react";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import { Button, Icon } from "semantic-ui-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "../styles/ag_grid.css";

import { api, ws } from "../api";
import BuildingMenu from "../components/BuildingMenu";
import DateRangePicker from "../components/DateRangePicker/DateRangePicker";
import * as DPT from "../components/DPTComboBox/dpt";


class BuildingActivities extends React.Component {
  constructor(props) {
    // const defaultKnxsource = "1.1.255"; // Default value for knxsource
    // const building = props.building || {}; // Ensure building is defined

    super(props);
    this.state = {
      groups: {},
      devices: {},
      columnDefs: this.createColDefs(),
      tab: "realtime",
      rowData: [],
      realtimeData: [],
    };

    this.gridOptions = {
      defaultColDef: { resizable: true },
      suppressMovableColumns: true,
    };
    this.props.setBuildingId(this.props.match.params.buildingId);
  }

  componentDidMount() {

  this.fetchBuilding(() =>  {
    this.fetchBuildingGroups(() => {
      this.fetchBuildingDevices(() => {
        //remove loading animation
        this.setLoading(false);
        //start websocket
        ws().then((socket) => {
          this.socket = socket;
          socket.on("datagram", (data) => {
            // console.log(data);
            data.time = moment(data.time).format("HH:mm:ss");
            if (
              Object.prototype.hasOwnProperty.call(
                this.state.groups,
                data.group
              )
            ) {
              data.groupName = this.state.groups[data.group].name;
              data.dpt = this.state.groups[data.group].dataType;
              if (data.dpt === undefined)
                data.dpt = this.state.groups[data.group].dpt;
              data._dpt = DPT.resolveDPT(data.dpt);
            }
            let oAd = data.origin.replace(/\./g, "/");

            // console.log(oAd,data.origin);
            if (Object.prototype.hasOwnProperty.call(this.state.devices, oAd)) {
              data.originName = this.state.devices[oAd].name;
            } else {
              if (data.origin === this.state.knxsource)
              data.originName = `KNXMonitor`;
            }

            if(Array.isArray(data.value)){
                // console.log('this', data.value);
                // console.log(data);
                data.value = data.value.join('-');
            }

            this.state.realtimeData.unshift(data);
            // console.log(this.state.realtimeData.length);

            if(this.state.realtimeData.length > 5000){ //if array gets this long pop the end of so we don't run out of memory in the browser.
              this.state.realtimeData.pop();
            }

            if (this.state.tab === "realtime" && this.state.paused !== true)
              this.gridApi.setRowData(this.state.realtimeData);
          });
          socket.emit("subscribe", {
            building: this.props.match.params.buildingId,
          });
        });
      });
    });
  });
  }
  componentWillUnmount() {
    this.closed = true;
    if (this.socket) this.socket.close();
  }

  createColDefs() {
    return [
      { headerName: "#", field: "id", width: 40 },
      { headerName: "Time", field: "time", width: 100 },
      {
        headerName: "Flags",
        field: "priority",
        width: 70,
        cellRenderer: (params) => {
          var item = params.data;
          var doc;
          if (item) {
            var formattedPrority = item.priority;
            switch (item.priority) {
              case "n":
                formattedPrority = "";
                break;
              case "s":
                formattedPrority =
                  '<span className="ui mini basic label black">sys</span>';
                break;
              case "h":
                formattedPrority =
                  '<span className="ui mini basic label orange">high</span>';
                break;
              case "a":
                formattedPrority =
                  '<span className="ui mini basic label red">alarm</span>';
                break;
              default:
            }
            var formattedCommand = item.command;
            switch (formattedCommand) {
              case "r":
                formattedCommand =
                  '<span className="ui mini basic label green">read</span>';
                break;
              case "w":
                formattedCommand =
                  '<span className="ui mini basic label">write</span>';
                break;
              case "a":
                formattedCommand =
                  '<span className="ui mini basic label blue">answer</span>';
                break;
              default:
            }
            doc =
              "<span>" +
              formattedCommand +
              formattedPrority +
              (item.changed
                ? '<span className="ui mini circular icon label"> <i className="database icon"></i></span>'
                : "") +
              "</span>";
          }
          return doc;
        },
      },
      { headerName: "Source Addr", field: "origin", width: 80 },
      { headerName: "Source", field: "originName", width: 200 },
      { headerName: "Dest Addr", field: "group", width: 80 },
      { headerName: "Destination", field: "groupName", width: 200 },
      {
        headerName: "Value",
        field: "value",
        width: 100,
        cellRenderer: (params) => {
          if (
            params.data.value !== null &&
            typeof params.data.value === "object" &&
            params.data.value.day !== undefined
          ) {
            // This is a DateTime, or possibly a Date, render it as a string.  zp zero-pads to 2 digits.
            const zp = (n) => String(n).padStart(2, "0");
            let d = `${params.data.value.year}-${zp(
              params.data.value.month
            )}-${zp(params.data.value.day)}`;
            if (params.data.value.hour !== undefined) {
              d += ` ${zp(params.data.value.hour)}:${zp(
                params.data.value.minute
              )}:${zp(params.data.value.second)}`;
            }
            return d;
          }
          if (
            params.data.dpt === "Date" &&
            typeof params.data.value == "string" &&
            params.data.value.endsWith("T00:00:00.000Z")
          ) {
            // remove the extra guff
            return params.data.value.replace("T00:00:00.000Z", "");
          }
          return params.data.value;
        },
      },
      {
        headerName: "DPT",
        field: "dpt",
        width: 100,
        cellRenderer: ({ data }) => {
          if (!data._dpt) {
            data._dpt = { text: "", id: "" };
          }
          return `${data._dpt.text} ${data._dpt.id ? data._dpt.id : ""}`;
        },
      },
    ];
  }

  setLoading(isLoading) {
    var loadingEles = document.querySelectorAll("._page.segment");
    if (loadingEles)
      Array.prototype.forEach.call(loadingEles, (ele) => {
        if (isLoading === true) ele.classList.add("loading");
        else ele.classList.remove("loading");
      });
  }

  fetchBuilding(cb) {
      api(`/building/basic/${this.props.match.params.buildingId}`).then((res) => {
        if (this.closed === true) return;

        // console.log(res.knxsource);

        if (res.knxsource)
         this.setState({ building: res, knxsource: res.knxsource || "1.1.255"}, () => {
           cb();
         });
        else cb();
      });
  }

  fetchBuildingGroups(cb) {
    api("/building/group/" + this.props.match.params.buildingId).then((res) => {
      if (this.closed === true) return;
      //console.log("loaded: ", res);
      if (res.groups)
        this.setState({ groups: res.groups }, () => {
          cb();
        });
      else cb();
    });
    // .catch((err) => {
    //   console.log(err);
    // });
  }
  fetchBuildingDevices(cb) {
    api("/building/device/" + this.props.match.params.buildingId).then(
      (res) => {
        console.log(res);
        if (this.closed === true) return;
        // console.log("loaded: ", res);
        if (res.devices)
          this.setState({ devices: res.devices }, () => {
            cb();
          });
        else cb();
      }
    );
    // .catch((err) => {
    //   console.log(err);
    // });
  }

  fetchHistoryData(start) {
    this.setLoading(true);
    api("/building/ts/_all/" + this.props.match.params.buildingId, {
      method: "POST",
      body: JSON.stringify({
        time: +start.toDate(),
      }),
    }).then((res) => {
      if (this.closed === true || this.state.tab !== "history") return;
      if (res.success) {
        //console.log("history: ", res.results);
        let rowData = res.results.map((r) => {
          let time = moment(r.time);
          if (time.isValid() === false) return undefined;
          r.oAd = r.o.replace(/\./g, "/");
          let ret = {
            time: time.format("DD-MM HH:mm:ss"),
            group: r.g,
            origin: r.o,
            priority: r.p,
            command: r.c,
            value: r.v,
          };

          if (Object.prototype.hasOwnProperty.call(this.state.groups, r.g)) {
            ret.groupName = this.state.groups[r.g].name;
            ret.dpt = this.state.groups[r.g].dataType;
            if (ret.dpt === undefined) ret.dpt = this.state.groups[r.g].dpt;
            ret._dpt = DPT.resolveDPT(ret.dpt);
          }
          if (Object.prototype.hasOwnProperty.call(this.state.devices, r.oAd)) {
            ret.originName = this.state.devices[r.oAd].name;
          }
          return ret;
        });
        this.setState({ rowData: rowData }, () => {
          this.setLoading(false);
        });
      }
    });
    // .catch((err) => {
    //   console.log(err);
    // });
  }

  render() {
    let realTimeGrid =
      this.state.tab === "realtime" ? (
        <div className="ag-grid ag-theme-balham" key="realtimeGrid">
          <AgGridReact
            onGridReady={(args) => {
              this.gridApi = args.api;
              args.api.sizeColumnsToFit();
            }}
            gridOptions={this.gridOptions}
            columnDefs={this.state.columnDefs}
            rowData={this.state.realtimeData}
            rowHeight="25"
          />
        </div>
      ) : undefined;

    let historyGrid =
      this.state.tab === "history" ? (
        <div className="ag-grid ag-theme-balham" key="historyGrid">
          <AgGridReact
            gridOptions={this.gridOptions}
            columnDefs={this.state.columnDefs}
            rowData={this.state.rowData}
            rowHeight="25"
          />
        </div>
      ) : undefined;

    return (
      <div style={{ background: "#f4f4f4" }}>
        <BuildingMenu {...this.props} />

        <div
          className="ui _page loading segment"
          style={{ height: "calc(100vh - 77px)", margin: 0, border: "none" }}
        >
          <div className="ui top attached menu content">
            <Button
              className={this.state.tab === "realtime" ? "active item" : "item"}
              onClick={() => {
                this.setState({ tab: "realtime", rowData: [] });
              }}
            >
              <Icon name="treatment" />
              Real time events
            </Button>
            <Button
              className={this.state.tab === "history" ? "active item" : "item"}
              onClick={() => {
                this.fetchHistoryData(moment().subtract(0, "days"));
                this.setState({ tab: "history", rowData: [] });
              }}
            >
              <Icon name="database" />
              Historical data
            </Button>
            {historyGrid ? (
              <div className="active item">
                <Icon name="calendar alternate outline" />
                <div className="ui small input">
                  <DateRangePicker
                    options={{
                      timePicker: true,
                      ranges: undefined,
                      singleDatePicker: true,
                      locale: { format: "DD-MM-YYYY HH:mm ±10" },
                    }}
                    onApply={(start, end) => {
                      this.fetchHistoryData(start, end);
                    }}
                  />
                </div>
              </div>
            ) : undefined}
            <div className="right menu">
              {realTimeGrid ? (
                <div className="item">
                  <button
                    className="ui compact labeled icon button"
                    onClick={() => {
                      if (this.state.paused === true) {
                        this.setState({ paused: false });
                      } else {
                        this.setState({ paused: true });
                      }
                    }}
                  >
                    <i
                      className={this.state.paused ? "play icon" : "pause icon"}
                    />
                    {this.state.paused ? "Resume" : "Pause"}
                  </button>
                </div>
              ) : undefined}
              <div className="ui right aligned category search item">
                <div className="ui transparent icon input">
                  <input
                    className="prompt"
                    type="text"
                    placeholder="Filter ..."
                    onChange={(event) => {
                      if (this.gridApi) {
                        this.gridApi.setQuickFilter(event.target.value);
                      }
                    }}
                  />
                  <Icon name="search" className="link" />
                </div>
                <div className="results"></div>
              </div>
            </div>
          </div>
          <div
            className="ui bottom attached segment"
            style={{ height: "calc(100% - 40px)", padding: 0 }}
          >
            {realTimeGrid}
            {historyGrid}
          </div>
        </div>
      </div>
    );
  }
}

export default BuildingActivities;
