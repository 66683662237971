import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Dropdown, Icon } from "semantic-ui-react";

import { api } from "../../api";
import ContentService from "../../content/contentService";
// contentService.current.getTitleAndInfoPopup('location-map-search')
//{contentService.current.getContent('location-time-functions','sub-heading')}
import "./default.css";

const TopBar = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [email, setEmail] = useState("");
  // Content loading state
  const [contentLoaded, setContentLoaded] = useState(false);
  const contentService = useRef(new ContentService("top-bar"));

  useEffect(() => {
    if(!contentLoaded){
      contentService.current.loadContent().then(() => {
        setContentLoaded(true);
      });
    }

    api("/user").then((res) => {
      if (res && res.email) {
        setEmail(res.email);
        if (res.subscription_status && !["active", "trialing"].includes(res.subscription_status)) {
          history.push("/subscription");
        }
        
      }
    });
  }, []);


  if (!contentLoaded) {
    return <div className="ui active small inline loader" />;
  }

  return (
    <div className="TopBar">
      <div className="content">
          <div className="Logo">
            <span style={{ color: "#64dd17", textShadow: "0 0 1px #068A00" }}>
              KNX
            </span>
            Monitor
          </div>
        {props.loggedIn ? (
          <ul>
            <li className={location.pathname === "/buildings" ? "active" : ""}>
              <Icon name="building" />
              <Link to="/buildings">{contentService.current.getContent('menu-my-buildings','item')}</Link>
            </li>
            {props.building !== undefined ? (
              <li
                className={
                  window.location.pathname.indexOf("/building/") > -1
                    ? "active"
                    : ""
                }
              >
                <Link to={"/building/" + props.building._id}>
                <Icon name="home" />
                  {props.building.name}
                </Link>
              </li>
            ) : undefined}
          </ul>
        ) : undefined}

        <div
          style={{
            width: "10em",
            position: "fixed",
            top: "0px",
            right: 0,
          }}
        >
          <div
            className="ui fluid selection dropdown"
            style={{
              backgroundImage: "linear-gradient(#F1F1F1,#DEDEDE 85%,#E2E2E2)",
              border: "0",
              height: "39px",
              borderRadius: "0",
            }}
          >
            <Dropdown text={contentService.current.getContent('account','item')}>
              <Dropdown.Menu style={{ position: "fixed" }}>
                <Link className="item" to={"/subscription"}>
                  <Icon name="user" />
                  {contentService.current.getContent('account-subscription','item')}
                </Link>
                <Dropdown.Divider />
                <button
                  className="item"
                  style={{ background: "#333", color: "white" }}
                  onClick={() => {
                    localStorage.removeItem("accessToken");
                    props.setLoggedIn(false);
                  }}>
                   <Icon name="x" />
                  {contentService.current.getContent('account-log-out','item')}
                </button>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
