import React, { createRef } from "react";
import { Icon, Flag } from 'semantic-ui-react';
import { api , ws } from "../../api";
import ContentService from "../../content/contentService";
import Terminal from "./Terminal";

const connectionButtonStyle = {
  position: "absolute",
  top: "-4.5em",
  right: 0,
  width: "150px",
};

const vpnFileSelectButton = {
  
  // position: "absolute",
  // top: "182px",
  // right: "700px",
  height: "34px",
  marginTop: "2px",
  marginRight: "10px",
  width: "150px",
  
};


const saveChangeButtonStyle = {
  position: "absolute",
  top: "-4.5em",
  right: "160px",
  width: "150px",
};

const testConnectionButtonStyle = {
  position: "absolute",
  top: "-4.5em",
  right: '160px',
  width: "150px",
};

const saveNewButtonStyle = {
  position: "absolute",
  top: "-4.5em",
  right: "0px",
  width: "150px",
};

class ConnectionStep extends React.Component {
  constructor(props) {
    super(props);

    // Initialize content loading
    this.contentService = new ContentService("connection-settings");

    this.state = {
      contentLoaded: false,
      name: props.building.name || '',
      ip_host: props.building.ip_host || '',
      tags: props.building.tags || { connectionType: props.building.vpn ? "gira-vpn" : "direct" },
      vpn: props.building.vpn || false,
      vpnFile: null, // State to hold the selected VPN file
      vpn_file_content: props.building.vpn_file_content || "", // State to hold the VPN file content
      vpn_info: props.building.vpn_info || {}, // State to hold the parsed VPN info 
      connectionStatus: props.building.logging_status,  // Track connection status
      newMessage: null, // Only holds the most recent message
      canAddBuilding: false, // see if we have enough info to add a building
      daemons: [],
      vpndaemons: [],
      connectionTypes:[],
      changedList:[],
      errorList:[],
    };
    this.socket = null;
    this.terminalRef = React.createRef();

  }

  componentDidMount() {
  // Only load if content is not yet loaded
    if (!this.state.contentLoaded) {
      // Load all content in parallel
      Promise.all([
        this.contentService.loadContent(),
        this.contentService.getConnectionTypes(),
        this.contentService.getDaemons(),
        this.contentService.getVPNDaemons()
      ]).then(([content, connectionTypes, daemons, vpndaemons]) => {
        this.setState({
          contentLoaded: true,
          connectionTypes: connectionTypes,
          daemons: daemons,
          vpndaemons: vpndaemons,
        });
      }).catch(error => {
        console.error("Error loading content:", error);
      });

    }
    this.setupWebSocket();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.building !== this.props.building) {
      this.setState({
        tags: {connectionType: this.props.building.tags.connectionType} || {connectionType: 'direct'},
        vpn: this.props.building.vpn || false,
        vpn_file_content: this.props.building.vpn_file_content || "",
        vpn_info: this.props.building.vpn_info || {},
        connectionStatus: this.props.building.logging_status,
      });

      // Re-subscribe if the building changes
      if (this.socket) {
        this.socket.emit("subscribe", {
          building: this.props.building._id,
        });
      }
    }
  }

  setupWebSocket() {
    if(!this.props.building._id){
      // console.log( `Skip setupWebSocket for new building`);
      return 
    }

    // Use the shared ws function to initialize the WebSocket
    ws().then((socket) => {
      this.socket = socket;

      // Subscribe to the status updates for the current building
      this.socket.emit("subscribe", {
        building: this.props.building._id,
      });

      // Listen for 'status' events from the server
      this.socket.on("status", (data) => {
        // console.log(data);
        const datasource =  data.daemon || data.daemon_name || data.source || "unknown" ;
        if (data.status === 'start') {
          this.setState({ connectionStatus: `TRYING TO CONNECT from  ${datasource}`});
        }

        if (data.status === 'stop') {
          this.setState({ connectionStatus: `DISCONNECTING from  ${datasource}`});
        }

        if (data.status === 'stopped') {
          this.setState({ connectionStatus: `DISCONNECTED`});
        }

        if (data.status === 'status') {
          if(data.message.includes('Connected')){
            this.setState({ connectionStatus: `CONNECTED to ${datasource}`});
          }
        }

        const newMessage = { source: datasource, status: data.status, message: data.message } // Send message to Terminal
        this.terminalRef.current.addLog(newMessage);
   
      });
    });
  }
   
  componentWillUnmount() {
    if (this.socket) {
      this.socket.close();  // Cleanup WebSocket when the component unmounts
    }
  }

  applyChanges() {
    // Destructure to exclude unwanted properties
    const { errorMessage, resolvedIp,domainStatus,domainSuccess, changed,newMessage,connectionStatus,contentLoaded, canAddBuilding,changedList, errorList , connectionTypes, daemons, vpndaemons, ...filteredState } = this.state;
    // Apply the remaining properties to this.props.building
    Object.assign(this.props.building, filteredState);
  }
  
  parseVpnFile = (content) => {
    const lines = content.split('\n');
    const vpn_info = {error: false,};

    lines.forEach(line => {
      if (line.startsWith('remote ')) {
        vpn_info.remote = line.split(' ')[1];
      } else if (line.startsWith('http-proxy ')) {
        vpn_info.httpProxy = line.split(' ')[1];
      } else if (line.startsWith('cipher ')) {
        vpn_info.cipher = line.split(' ')[1];
      } else if (line.startsWith('resolv-retry ')) {
        vpn_info.resolvRetry = line.split(' ')[1];
      } else if (line.startsWith('dev ')) {
        vpn_info.deviceType = line.split(' ')[1];
        if(vpn_info.deviceType === "tap"){
          vpn_info.error = true;
        }
      } else if (line.startsWith('proto ')) {
        vpn_info.protocol = line.split(' ')[1];
        if(vpn_info.protocol === "udp"){
          vpn_info.error = true;
        }
      }
    });

    return vpn_info;
  };

  pushChanges(item){
    if (!this.state.changedList.includes(item)) {
      this.state.changedList.push(item);
    }
  }
  popChanges(item){
    // Use filter to remove 'ip_host' if it exists
    this.setState((prevState) => ({
      changedList: prevState.changedList.filter(pitem => pitem !== item)
    }));
  }


  pushErrors(item){
    if (!this.state.errorList.includes(item)) {
      // console.log(`adding `,item)
      this.state.errorList.push(item);
    }
  }

  popErrors(item){
    // Use filter to remove 'ip_host' if it exists
    // this.state.errorList = this.state.errorList.filter(pitem => pitem !== item);
    this.setState((prevState) => ({
      errorList: prevState.errorList.filter(pitem => pitem !== item)
    }));

    // console.log (`removing `, item , this.state.errorList);
  }

  readVpnFile = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const fileContent = e.target.result;
      // this.setState({ vpn_file_content: fileContent });
      const vpn_info = this.parseVpnFile(fileContent);
  
      // Check if both remote and cipher values exist
      if (!vpn_info.remote || !vpn_info.cipher) {
        this.popChanges('vpn-file');
        this.pushErrors('vpn-file');
        // console.log(`setting error in readVPNFIle`)
        this.setState({vpn_info: "" , vpn_file_content: "" , canAddBuilding: false , errorMessage: this.contentService.getContent('vpn-file-content','validation',`File uploaded is not a valid .ovpn file.`)},
        () => {
          // This callback is executed after the state update
          this.validateAddBuilding(); // Now this will use the updated ip_host value
        });
      } else {
        // console.log('setting vpn info');
        this.pushChanges('vpn-file');
        this.popErrors('vpn-file');
        this.setState({ vpn_info: vpn_info, vpn_file_content: fileContent , errorMessage: "", changed: true },
          () => {
            // This callback is executed after the state update
            this.validateAddBuilding(); // Now this will use the updated ip_host value
          });
      }
    };
    reader.readAsText(file);
  };

  handleVpnFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      this.setState({ vpnFile: file });
      this.readVpnFile(file)
    }
  };
  
  // Debounce helper function
  debounce(func, wait) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  };

  handleSourceAddressChange = (e) => {
    const val = e.target.value.trim();

    const regex = /^(25[0-5]|2[0-4][0-9]|1?[0-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1?[0-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1?[0-9]?[0-9])$/;
    const valid = val === '' ? true : regex.test(val);

    if(valid){
      // if(this.props.building.knxsource !== val){
        this.pushChanges('knx-source');
        this.popErrors('knx-source');
        this.setState({ knxsource: val, errorMessage: "", changed: true },
        () => {
          // This callback is executed after the state update
          this.validateAddBuilding(); // Now this will use the updated ip_host value
        });          
    } else {
      this.popChanges('knx-source');
      this.pushErrors('knx-source');
      this.setState({ errorMessage: this.contentService.getContent('knxsource','validation') },
      () => {
        // This callback is executed after the state update
        this.validateAddBuilding(); // Now this will use the updated ip_host value
      });
    }
  }

  handleConnectionTypeChange = (e) => {

    // console.log(`error`,this.state.errorList)
    // console.log(`changes`,this.state.changedList)

      let isChanged = false;
      const selectedType = e.target.value;

      if(selectedType === 'none'){
        this.popChanges('connectionType')
        this.pushErrors('connectionType-none');
        this.setState({ tags: {connectionType: selectedType},errorMessage: this.contentService.getContent('connection-type-none','validation','Please select a connection type') }, 
        () => {
          // This callback is executed after the state update
          this.validateAddBuilding(); // Now this will use the updated ip_host value
        }); 
        return
      } else {
        this.popErrors('connectionType-none')
      }

      const isVPN = e.target.options[e.target.selectedIndex].getAttribute("vpntype") === "true";

      if (this.state.vpn !== isVPN) {
        this.pushChanges('vpn')
        this.popErrors('vpn')
        isChanged =true;
      } else {
        this.popChanges('vpn')
      }

      if (this.state.tags.connectionType !== selectedType) {
        this.pushChanges('connectionType')
        this.popErrors('connectionType')
        isChanged =true;
      } else {
        this.popChanges('connectionType')
      }

      if(isVPN === true &&  this.state.vpn_file_content === ""){
        this.pushErrors('vpn-file')
        this.setState({changed: true,vpn: isVPN, tags: {connectionType: selectedType}, errorMessage: this.contentService.getContent('vpn-info-missing','validation') }, 
        () => {
          // This callback is executed after the state update
          this.validateAddBuilding(); // Now this will use the updated ip_host value
        }); 
        return
      } else{
        this.popErrors('vpn-file')
      }

      if(isChanged)
        this.setState({tags: {connectionType: selectedType}, errorMessage: "" ,vpn: isVPN, changed: true}, 
          () => {
            // This callback is executed after the state update
            this.validateAddBuilding(); // Now this will use the updated ip_host value
          });
  }

  handleSaveChanges(){
      // save changes
      api("/building/update/" + this.props.building._id, {
        method: "POST",
        body: JSON.stringify(this.state),
      }).then(() => {
        this.applyChanges()
        // Object.assign(this.props.building, this.state);
        this.setState((prevState) => delete prevState.name);
        this.setState({ changed: false , changedList: [],errorList:[] });
        this.forceUpdate();
      });
  };
  
  // Debounced Domain Checker
  domainChecker = this.debounce(async (domain) => {
    try {
        const response = await api(`/check-domain?domain=${encodeURIComponent(domain)}`);
        // console.log('Domain Response:', response);
        // Update component based on whether the domain resolves
        if (response.success) {
            this.setState({ domainSuccess: response.success, domainStatus: `Domain resolves to: ${response.addresses.join(', ')}`, resolvedIp: response.addresses.length === 1 ? response.addresses[0] : response.addresses.join(', ') });
        } else {
            this.setState({ domainSuccess: response.success, domainStatus: 'Domain does not resolve' , resolvedIp: 'Unable to resolve domain' });
        }

    } catch (error) {
        // console.error("Error resolving domain:", error);
        this.setState({ domainStatus: 'Domain resolution error' });
    }
  }, 2000);


  handleIpHostChange = (e) => {
    const val = e.target.value.trim();
   
    if (this.isValidIpAddress(val) || this.isValidDomain(val)) {
      this.popErrors('ip-host')
      // console.log('ipvalid',this.state.ip_host, val,this.state.ip_host !== val, this.state.errorList )

      // if(this.state.ip_host !== val){
        this.pushChanges('ip-host');
        this.setState({ip_host: val, errorMessage: "", changed: true }, // add callback so validateAddBuilding has correct info
          () => {
            // This callback is executed after the state update
            if (this.isValidDomain(val)) {
              this.domainChecker(val); // Perform DNS lookup if it's a domain
            }
            this.validateAddBuilding(); // Now this will use the updated ip_host value
          });
      
    } else {
      this.pushErrors('ip-host')
      this.setState({canAddBuilding: false ,ip_host: val, resolvedIp: '',domainStatus: '', errorMessage: this.contentService.getContent('ip-host','validation') }, 
      () => {
        // This callback is executed after the state update
        this.validateAddBuilding(); // Now this will use the updated ip_host value
      });
    }
   
  };

  isValidIpAddress(ip) {
    const ipRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    const testResult = ipRegex.test(ip);
    // console.log('IP Test:', ip, testResult);
    return testResult;
  }
  
  isValidDomain(domain) {
    const domainRegex = /^(?!:\/\/)(?!.*\d+\.\d+\.\d+\.\d+)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
    
    const testResult = domainRegex.test(domain);
    // console.log('Domain Test:', domain, testResult);
    return testResult;
  }

  validateAddBuilding(){
    // console.log(`validateBldg`,this.state.vpn,this.state.name, this.state.errorList) 
    // console.log(this.state)
      var canAdd = true;
      var errorMsg = ''

      if(this.props.building.id){ // there is an id .. so no adding required just return
        return;
      }

      if(!this.state.name || this.state.errorList.includes('building-name')){
        canAdd = false;
        errorMsg = this.contentService.getContent('building-name','validation-undefined')
      }

      if(!this.state.ip_host || this.state.errorList.includes('ip-host')){
        canAdd = false;
        errorMsg = this.contentService.getContent('ip-host','validation') 
      }

      if(this.state.errorList.includes('vpn-file')){
        canAdd = false;
        if(this.state.errorMessage === ""){
          //leave existing error if e.g. the vpn file is wrong type.
          errorMsg =this.contentService.getContent('vpn-info-missing','validation')
        } else {
          errorMsg = this.state.errorMessage; // don't leave it empty
        }
      }      
      if(this.state.errorList.includes('connectionType-none')){
        canAdd = false;
        errorMsg = this.contentService.getContent('connection-type-none','validation','Please select a connection type'); 
      }  

      if(this.state.errorList.includes('ip-port')){
        canAdd = false;
        errorMsg = this.contentService.getContent('port','validation') 
      }

      if(this.state.errorList.includes('knx-source')){
        canAdd = false;
        errorMsg = this.contentService.getContent('knxsource','validation') 
      }      

      if(this.state.vpn && !this.state.vpn_info.remote){
        canAdd = false;
        if(this.state.errorMessage === ""){
          errorMsg =  this.contentService.getContent('vpn-info-missing','validation')
        } else {errorMsg = this.state.errorMessage};
      }
 
      // console.log(`validate add`, canAdd, errorMsg);
      this.setState({canAddBuilding: canAdd , errorMessage: errorMsg })

  }

  handleAddNewBuilding() {

    // console.log(`handleAddNewBuilding`, this.state.canAddBuilding, this.state.errorList);
    this.validateAddBuilding()

    if(!this.state.canAddBuilding){
      return; 
    }

    if(this.state.errorList.length > 0){
      // console.log(this.state.errorList)
      return
    }

    this.setState({ errorMessage: "" });
    this.applyChanges();
    // console.log(this.props.building)
    // console.log(this.state.building);

    if (this.state.name && this.state.ip_host) {
      //console.log(this.state.building);
      window.$("#btnNewBuilding").addClass("disabled");
      window.$("#btnNewBuilding").addClass("loading");
      api("/buildings/new", {
        method: "POST",
        body: JSON.stringify(this.props.building),
      }).then((res) => {
        if (res.success === true) { // Navigate to the settings page to continue.
          this.props.routerHistory.push(`/building/settings/${res.id}`);
        } else if (res.error) {
          window.$("#btnNewBuilding").removeClass("disabled");
          window.$("#btnNewBuilding").removeClass("loading");
          this.setState({ errorMessage: res.error });
        }
      });
    } else {
      if(!this.state.name){
        this.pushErrors('building-name')
        this.setState({ errorMessage: this.contentService.getContent('building-name','validation-undefined') });
        return
      }
      if(!this.state.ip_host){
        this.pushErrors('ip-host')
        this.setState({ errorMessage: this.contentService.getContent('ip-host','validation')});
        return
      }
      
    }
  }

  handlePortChange = (e) => {
    const val = e.target.value.trim();
    
    if (!isNaN(val) && +val >= 0 && +val <= 65535) {
        this.pushChanges('ip-port')
        this.popErrors('ip-port')
        this.setState({ ip_port: val, errorMessage: "" ,changed: true}, // add callback so validateAddBuilding has correct info
        () => {
          this.validateAddBuilding(); // Now this will use the updated ip_host value
        });
    } else {
      this.pushErrors('ip-port')
      this.setState({ ip_port: val,changed: true, errorMessage: this.contentService.getContent('port','validation')}, 
      () => {
        this.validateAddBuilding(); // Now this will use the updated ip_host value
      });
    }
  };
  
  handleBuildingNameChange = (e) => {
    let val = e.target.value.trim();
      // Regular expression for allowed characters: letters, numbers, -, and _
    const regex = /^[a-zA-Z0-9-_. ]+$/;
  
    if (val === "") {
      this.pushErrors('building-name')
      this.setState({canAddBuilding: false ,errorMessage: this.contentService.getContent('building-name','validation-undefined') });
      return;
    }
  
    if (!regex.test(val)) {
      this.pushErrors('building-name')
      this.setState({canAddBuilding: false , errorMessage: this.contentService.getContent('building-name','validation') });
    } else {
        this.pushChanges('building-name');
        this.popErrors('building-name')
        this.setState({ name: val, errorMessage: "", changed: true },
          () => {
            // This callback is executed after the state update
            this.validateAddBuilding(); // Now this will use the updated ip_host value
          });
      }

  };

  wrapper = createRef();


  render() {
    const {contentLoaded, daemons,vpndaemons, connectionTypes,vpn, vpn_info,connectionStatus,errorMessage  } = this.state;

    if (!contentLoaded) {
      return <div className="ui active small inline loader" />;
    }

    return (
      <div ref={this.wrapper}>
        <div className="ui form">
          {/* Save change, hidden when new building */}
          {this.props.building._id && this.state.changedList.length > 0 &&  this.state.errorList.length <= 0 ? (
            // <div className="fields">
              <button
                className="ui positive button"
                style={saveChangeButtonStyle}
                onClick={(e) =>  this.handleSaveChanges(e)}>
                <Icon name='save' />
                {this.contentService.getContent('btn-save-changes', 'button-text')}
              </button>
            // </div>
          ) : !this.props.building._id && this.state.canAddBuilding ? (
                  <button
                    id="btnNewBuilding"
                    className="ui primary button"
                    style={saveNewButtonStyle}
                    onClick={(e) => this.handleAddNewBuilding(e)}>
                    <Icon name="save" />
                    {this.contentService.getContent('btn-save-new-building', 'button-text')}
                  </button>
          ) : undefined }

           {/* Start/Stop/Test Connection */}
           <div className="fields">
            {this.props.building.enabled === true ? (
              <button
                className="ui negative button"
                style={connectionButtonStyle}
                onClick={() => {
                  api("/building/stop/" + this.props.building._id, {
                    method: "POST",
                  }).then(() => {
                    this.props.building.enabled = false;
                    this.forceUpdate();
                  });
                }}
              >
                <Icon name='stop' />
                {this.contentService.getContent('btn-stop', 'button-text')}
              </button>
            ) : this.props.building.enabled === undefined ? 
            ( !vpn  && !errorMessage && this.state.ip_host && (
              <div>
                <button
                  className="ui teal testConnection button"
                  style={testConnectionButtonStyle}
                  onClick={(e) => {
                    if (window.$(e.target).hasClass("disabled")) return;
                    let btn = e.target;
                    window.$(btn).addClass("disabled");
                    window.$(btn).addClass("loading");
                    this.setState({ connectionStatus: `Trying Connection from Multiple Locations to ${this.state.ip_host} and port ${this.state.ip_port || 3671} .`});
                    api("/building/test", {
                      method: "POST",
                      body: JSON.stringify({
                        ip_host:
                          this.state.ip_host ||
                          window
                            .$(this.wrapper.current)
                            .find(".ip_host.field input")
                            .val(),
                        ip_port: this.state.ip_port || 3671,
                      }),
                    }).then((res) => {
                      window.$(btn).removeClass("disabled");
                      window.$(btn).removeClass("loading");
                      if (res.success) {
                        this.setState({ connectionStatus: `✓ Connected. ${this.state.ip_host}:${this.state.ip_port || 3671}`});
                      } else {
                        this.setState({ connectionStatus: `${res.error} Unable to Connect to ${this.state.ip_host}:${this.state.ip_port || 3671}`});
                      }
                    });
                  }}
                >
                <Icon name='wifi' />
                {this.contentService.getContent('btn-test', 'button-text')}
                </button>
              </div>
            )) : (
              <button
                className="ui basic positive button"
                style={connectionButtonStyle}
                onClick={() => {
                  api("/building/start/" + this.props.building._id, {
                    method: "POST",
                  }).then((res) => {
                    if (res.success === true) {
                      this.setState({ errorMessage: "" });
                      this.props.building.enabled = true;
                      this.forceUpdate();
                    } else if (res.error) {
                      this.setState({ errorMessage: res.error });
                    }
                  });
                }}
              >
                <Icon name='play' />
                {this.contentService.getContent('btn-start', 'button-text')}
              </button>
            )}
          </div>
          {/* Display current connection status or errors when making changes */}
          {connectionStatus && !this.state.errorMessage ? ( this.props.building.project_uploaded ? (
            <div className="ui positive message" style={{ display: 'block', marginTop: '0px' }}>
              <strong>{this.contentService.getContent('connection-status','text')}</strong> {connectionStatus}
            </div>) : (
            <div className="ui positive message" style={{ display: 'block', marginTop: '0px' }}>
              <Icon size="large" name="exclamation circle"/><strong>{this.contentService.getContent('connection-status-no-project-file','text',`Please upload your ETS Project file and set building location before connecting`)}</strong>
            </div>)
           ) : this.state.errorMessage ? (
            <div className="ui negative message" style={{ display: 'block', marginTop: '0px' }}>
              <Icon size="large" name="exclamation circle"/>{this.state.errorMessage}
            </div>
          ) : ( (
            <div className="ui message" style={{ display: 'block', marginTop: '0px' }}>
              <p>{this.contentService.getContent('connection-status-new','text')}</p>
            </div>
            ))
          }
          {/* Name */}
          <div className="fields">
            <div className="eight wide field">
              <label>
                {this.contentService.getTitleAndInfoPopup('building-name')}
                <input
                  type="text"
                  placeholder=""
                  defaultValue={this.props.building.name}
                  onChange={this.handleBuildingNameChange}
                  style={{ display: 'block', marginTop: '5px' }}                  
                />
              </label>
            </div>
            <div className="four wide field">
              <label>
                {this.contentService.getTitleAndInfoPopup('connection-type')}
                <select
                  name="connectionType"
                  value={this.state.tags.connectionType}
                  onChange={this.handleConnectionTypeChange}
                  style={{ display: 'block', marginTop: '5px' , width: '300px' }}>
                  <option vpntype="false" value="none">{this.contentService.getContent('connection-type-none', 'item')}</option>
                  {connectionTypes.map((ct, index) => (
                      <option key={index} vpntype={ct.vpntype.toString()} value={ct.value}>{ct.name}</option>
                  ))}
                </select>
                </label>
              </div>
          </div>
          {/* IP Address/DNS Name field with dynamic label */}
          <div className="fields">
            <div className="eight wide ip_host field">
              <label>
                {vpn ? this.contentService.getTitleAndInfoPopup('ip-host-vpn') : this.contentService.getTitleAndInfoPopup('ip-host')}
                <div style={{ position: 'relative', width: '100%' }}>
                <input
                  type="text"
                  placeholder=""
                  defaultValue={this.props.building.ip_host}
                  onChange={this.handleIpHostChange}
                  style={{ display: 'block', width: '100%', marginTop: '5px', paddingRight: '80px' }}               
                />
                  <span
                      style={{
                        position: 'absolute',
                        right: '10px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        color: this.state.domainSuccess ? '#93cc81' : '#deabab', // Light grey color
                        pointerEvents: 'none',
                        fontSize: '0.9em',
                      }}
                    >
                      {this.state.resolvedIp || ''}
                    </span>
                  </div>
              </label>
            </div>
            <div className="two wide ip_port field">
              <label>
                {vpn ? this.contentService.getTitleAndInfoPopup('port-vpn') : this.contentService.getTitleAndInfoPopup('port')}
                <input
                  type="text"
                  placeholder="3671"
                  defaultValue={this.props.building.ip_port}
                  onChange={this.handlePortChange}
                  style={{ display: 'block', marginTop: '5px' }}
                />
              </label>
            </div>
            {vpn && (
                <div className="field">
                {/* Hidden file input */}
                <input
                  type="file"
                  name="vpnFile"
                  accept=".ovpn"
                  onChange={this.handleVpnFileChange}
                  style={{ display: 'none' }} // Hides the default file input
                  ref={(vpnFileInput) => this.vpnFileInput = vpnFileInput} // Reference to trigger click
                />

                {/* Custom styled button */}
                <label>{this.contentService.getTitleAndInfoPopup('vpn-file-upload')}</label>
                <button
                  type="button"
                  className={vpn_info.remote ? "ui basic button" : "ui blue basic button"} // Use the same styling class
                  onClick={() => this.vpnFileInput.click()} // Triggers the file input click
                  style={vpnFileSelectButton}
                  >
                  <Icon name='shield alternate' />
                  {!vpn_info.remote ? this.contentService.getContent('vpn-file-select', 'button-text', `Select .ovpn file`) : this.contentService.getContent('vpn-file-update', 'button-text', `Update .ovpn file`) }
                </button>
                </div>
             
            )}



          </div>
            {/* Conditionally render VPN file upload */}
            {vpn &&  (
              <div className="ui ignored info message">
              <div className="header">
                {this.contentService.getContent(`connection-${this.state.tags.connectionType}-content`, 'header')}
              </div>
                  <div style={{ marginTop: '10px' }}>{this.contentService.getTitleAndInfoPopup(`connection-${this.state.tags.connectionType}-content`,null,null,true)}</div>
                 
                  {vpn_info.remote ? (
                    <div className="vpn-info">
                        <p>
                          <strong>
                            {vpn_info.error === false ? (
                              <>
                                <Icon color="blue" name="info" />
                                {this.contentService.getContent('connection-vpn-file-info-ok', 'text')}
                              </>
                            ) : (
                              <>
                                <Icon size="large" color="red" name="exclamation circle" />
                                {this.contentService.getContent('connection-vpn-file-info-error', 'text', 'Your VPN file has some configuration errors.')}
                              </>
                            )}
                          </strong>
                        </p>
                        <ul className="vpninfo-list" style={{ marginTop: '15px' }}>

                        <li className="vpninfo-item"><i className="green check circle outline icon"></i><strong>Remote:</strong> {vpn_info.remote}</li>
                        {vpn_info.httpProxy && (
                        <li className="vpninfo-item"><i className="green check circle outline icon"></i><strong>HTTP Proxy:</strong> {vpn_info.httpProxy}</li>
                        )}
                        {vpn_info.httpProxy && (
                        <li className="vpninfo-item"><i className="green check circle outline icon"></i><strong>Resolv Retry:</strong> {vpn_info.resolvRetry}</li>
                        )}                        
                        <li className="vpninfo-item"><i className="green check circle outline icon"></i><strong>Cipher:</strong> {vpn_info.cipher}</li>
                        <li className="vpninfo-item">{vpn_info.protocol === "tcp" ? <i className="green check circle outline icon"></i> : <i className="red stop circle outline icon"></i> }<strong>Protocol:</strong> {vpn_info.protocol}
                          {" "}{vpn_info.protocol !== "tcp" && (<div className="ui negative message"><i className="exclamation triangle icon"></i>{this.contentService.getContent('vpn-wrong-protocol', 'text', `Please make sure your your VPN server is set to use "tcp" protocol.`) } </div>)}
                        </li>
                        <li className="vpninfo-item">{vpn_info.deviceType === "tun" ? <i className="green check circle outline icon"></i> : <i className="red stop circle outline icon"></i> }<strong>Virtual Device Type:</strong> {vpn_info.deviceType}
                          {" "}{vpn_info.deviceType !== "tun" && (<div className="ui negative message"><i className="exclamation triangle icon"></i>{this.contentService.getContent('vpn-wrong-device-type', 'text', `Please change your VPN server to use network device type "tun"`) }</div>)}
                        </li>
                        </ul>
                    </div>
                  ) : (
                    <div style={{ marginTop: '10px', color: "blue"}}>
                        <p><strong>
                          {this.contentService.getContent(`connection-vpn-upload-content`, 'text')}
                          </strong></p>
                    </div>
                  ) }  

                  <div style={{ color:'#041875',  marginTop: '20px' }}><Icon name="globe"/>{this.contentService.getContent('connection-vpn-server-info', 'text')}</div>
                  <ul className="daemon-list" style={{ marginTop: '15px' }}>
                    {vpndaemons.map((daemon, index) => (
                      <li key={index} className="daemon-item">
                        <Flag name={daemon.country} />
                        <span className="city-name">{daemon.location}</span>
                        <span className="ip-address">{daemon.ipaddress}</span>
                      </li>
                    ))}
                  </ul>                  
            </div>
          )}
          {!vpn && (
            <div className="ui ignored info message">
              <div className="header">
              {this.contentService.getContent('connection-direct-content', 'header', 'Security Information.')}
              </div>
                  <div style={{ marginTop: '10px' }}>{this.contentService.getTitleAndInfoPopup('connection-direct-firewall')}</div>
                  <ul className="daemon-list" style={{ marginTop: '10px' }}>
                    {daemons.map((daemon, index) => (
                      <li key={index} className="daemon-item">
                        <Flag name={daemon.country} />
                        <span className="city-name">{daemon.location}</span>
                        <span className="ip-address">{daemon.ipaddress}</span>
                      </li>
                    ))}
                  </ul>
                  <div>{this.contentService.getContent('connection-direct-port-info', 'text')}</div>
            </div>
          )}

          {/* Terminal component*/}
          <div ref={this.wrapper}>
            <Terminal ref={this.terminalRef} />
          </div>
          {/* <div className="ui section divider"></div> */}
          {/* Default source address */}
          <div className="fields">
            <div className="six wide field">
              <label>
                {this.contentService.getTitleAndInfoPopup('source-address')}
              </label>
              <input
                type="text"
                placeholder="1.1.255"
                defaultValue={this.props.building.knxsource}
                onChange={this.handleSourceAddressChange}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ConnectionStep;
