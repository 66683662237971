// contentCache.js
import { api } from "../api";

class ContentCache {
  static cache = {};
  static daemons = {};
  static connectionTypes = {};
  static CACHE_DURATION_MS = 60 * 60 * 1000; // Cache expires in 1 hour
  static lastLoadTime = {};
  static lastDaemonLoadTime = {};
  static lastConnectionTypeLoadTime = {};
  static ContentApiCalled = false;
  static DaemonApiCalled = false;
  static ConnectionApiCalled = false;
  static loadingPromise = null; // Track ongoing API call
  static loadingDaemonPromise = null; // Track ongoing API call
  static loadingConnectionTypesPromise = null; // Track ongoing API call

  static loadFromLocalStorage(key) {
    const storedData = localStorage.getItem(key);
    if (!storedData) return null;
  
    const { data, timestamp } = JSON.parse(storedData);
    const isExpired = Date.now() - timestamp > ContentCache.CACHE_DURATION_MS;
  
    return isExpired ? null : data; // Return data if it's still valid
  }
  

  static saveToLocalStorage(key, data) {
    const dataWithTimestamp = {
      data,
      timestamp: Date.now() // Save the current time
    };
    localStorage.setItem(key, JSON.stringify(dataWithTimestamp));
  }

  // Load daemons and cache in memory and localStorage
  static async loadDaemons(language = "en") {

    const cacheValid = ContentCache.DaemonApiCalled;

    if (ContentCache.daemons[language] && cacheValid){
      // console.log(`cache valid for Daemons ${language} returning from object`)
       return ContentCache.daemons[language];
    }
    // If a load operation is in progress, return the existing promise
  // If a load operation is in progress, return the existing promise
  if (ContentCache.loadingDaemonPromise) {
    // console.log(`daemon call in progress returning promise`);
    return ContentCache.loadingDaemonPromise;
  }

    // Otherwise, initiate a new API request
    ContentCache.loadingDaemonPromise = new Promise(async (resolve, reject) => {
      try {
        const cachedDaemons = ContentCache.loadFromLocalStorage(`daemons_${language}`);
        if (cachedDaemons) {
          // console.log(`loaded daemons from local storage`);
          ContentCache.daemons[language] = cachedDaemons;
          ContentCache.DaemonApiCalled = true;
          resolve(cachedDaemons);
          ContentCache.loadingDaemonPromise = null; // Clear loading promise
          return;
        }
        // Fetch from API
        // console.log(`loading daemons from api`);
        const response = await api(`/content/daemons`);

        ContentCache.DaemonApiCalled = true;
        const allContent = response.daemons || [];
        // Cache and store content
        ContentCache.daemons[language] = allContent;
        ContentCache.saveToLocalStorage(`daemons_${language}`, allContent);
        ContentCache.lastDaemonLoadTime[language] = Date.now();
        // console.log(allContent);
        resolve(allContent);
        ContentCache.loadingDaemonPromise = null; // Clear loading promise
      } catch (error) {
        // console.error("Error loading Daemons:", error);
        ContentCache.loadingDaemonPromise = null; // Clear loading promise in case of failure
        reject(error);
      }
    });

    return ContentCache.loadingDaemonPromise;
  }

  // Load connection types, cache in memory and localStorage
  static async loadConnectionTypes(language = "en") {

    const cacheValid = ContentCache.ConnectionApiCalled;

    if (ContentCache.connectionTypes[language] && cacheValid){
      // console.log(`cache valid for Connection Types ${language} returning from object`)
       return ContentCache.connectionTypes[language];
    }
    // If a load operation is in progress, return the existing promise
    if (ContentCache.loadingConnectionTypesPromise) {
      // console.log(`Connection Type call in progress returning promise`)
      return ContentCache.loadingConnectionTypesPromise
      // return ContentCache.loadingConnectionTypesPromise.then(() => {
      //   return ContentCache.connectionTypes[language];
      // });
    }

    // Otherwise, initiate a new API request
    ContentCache.loadingConnectionTypesPromise = new Promise(async (resolve, reject) => {
      try {
        const cachedConnectionTypes = ContentCache.loadFromLocalStorage(`connectionTypes_${language}`);
        if (cachedConnectionTypes) {
          // console.log(`loaded connection types from local storage`);
          ContentCache.connectionTypes[language] = cachedConnectionTypes;
          ContentCache.ConnectionApiCalled = true;
          resolve(cachedConnectionTypes);
          ContentCache.loadingConnectionTypesPromise = null; // Clear loading promise
          return;
        }
        // Fetch from API
        // console.log(`loading connection types from api`);
        const response = await api(`/content/connectiontypes`);
        ContentCache.ConnectionApiCalled = true;
        const allContent = response.connectionTypes || [];
        // Cache and store content
        ContentCache.connectionTypes[language] = allContent;
        ContentCache.saveToLocalStorage(`connectionTypes_${language}`, allContent);
        ContentCache.lastConnectionTypeLoadTime[language] = Date.now();
        resolve(allContent);
        ContentCache.loadingConnectionTypesPromise = null; // Clear loading promise
      } catch (error) {
        // console.error("Error loading ConnectionTypes:", error);
        ContentCache.loadingConnectionTypesPromise = null; // Clear loading promise in case of failure
        reject(error);
      }
    });

    return ContentCache.loadingConnectionTypesPromise;
   
  }

  // Load general content, organize by area, cache in localStorage
  static async loadContent(language = "en", area) {

    const cacheValid = ContentCache.ContentApiCalled;

    if (ContentCache.cache[language] && cacheValid){
      // console.log(`cache valid for ${language} . ${area} returning from object`)
       return area ? ContentCache.cache[language][area] : ContentCache.cache[language] ;
    }
    // If a load operation is in progress, return the existing promise
    if (ContentCache.loadingPromise) {
      // console.log(`content call in progress returning promise for ${area}`)
      return ContentCache.loadingPromise.then(() => {
        return area ? ContentCache.cache[language]?.[area] || [] : ContentCache.cache[language] || {};
      });
    }

    // Otherwise, initiate a new API request
    ContentCache.loadingPromise = new Promise(async (resolve, reject) => {
      try {
        const cachedContent = await ContentCache.loadFromLocalStorage(`content_${language}`);
        if (cachedContent) {
          // console.log(`loaded content from local storage`);
          ContentCache.cache[language] = cachedContent;
          ContentCache.ContentApiCalled = true;
          ContentCache.loadingPromise = null; // Clear loading promise
          resolve(area ? cachedContent[area] : cachedContent);
          return;
        }
        // Fetch from API
        // console.log(`loading content from api`);
        const response = await api(`/content/${language}`);
        ContentCache.ContentApiCalled = true;
        const allContent = response.content || [];
        const organizedContent = allContent.reduce((acc, item) => {
          if (!acc[item.area]) acc[item.area] = [];
          acc[item.area].push(item);
          return acc;
        }, {});

        // Cache and store content
        ContentCache.cache[language] = organizedContent;
        ContentCache.saveToLocalStorage(`content_${language}`, organizedContent);
        ContentCache.lastLoadTime[language] = Date.now();

        ContentCache.loadingPromise = null; // Clear loading promise
        resolve(area ? organizedContent[area] : organizedContent);
      } catch (error) {
        // console.error("Error loading content:", error);
        ContentCache.loadingPromise = null; // Clear loading promise in case of failure
        reject(error);
      }
    });

    return ContentCache.loadingPromise;
  }

  static getContentByArea(area, language = "en") {
    const languageCache = ContentCache.cache[language];
      return languageCache ? languageCache[area] || [] : [];
  }
}

export default ContentCache;
